@import '../../../../assets/scss/variables';

app-cards {
  .tab {
    margin: 20px 0;
  }
  .list-cards{
    border-bottom: 1px solid $gray-color1;
    padding-bottom: 20px;
  }
}

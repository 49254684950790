@import '../../../../assets/scss/variables';

app-upload-video {
  width: 100%;
  .upload-video {
    display: flex;
    align-items: center;
    .mat-spinner {
      margin-left: 10px;
    }
  }
  .file-name{
    cursor: pointer;
  }
  .upl__image{
    width: 100%;
  }
  .name {
    padding-left: 10px;
    font-size: 13px;
    color: #555;
  }
  .upload-section {
    width: 100%;
    height: 80px;
    label {
      flex-direction: row;
    }
    .fa-music, .fa-camera, .fa-video {
      font-size: 20px;
      padding-right: 18px;
      color: $blue-color11;
    }
    img {
      width: 100%;
      position: absolute;
      left: 0;
      text-align: center;
      top: 0;
    }
  }
}

@import '../../../../assets/scss/variables';

app-read-more-section {
  .content-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .section-title {
    font-size: 18px;
  }
  .item-label{
    width: 200px;
    min-width: 200px;
    padding:0 20px;
    text-align: right;
  }
}

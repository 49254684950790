@import '../../../../assets/scss/variables';

app-card-component {
 .icon {
    margin: 0 10px 10px 0;
    font-size: 20px;
    padding: 5px;
    border: 2px solid transparent;
    cursor: pointer;
   color: #676a6c;
  }
  app-upload-image .upl__image{
    width: 50%;
  }
  [type=radio]:checked + span {
    outline: 2px solid #f00;
  }
}

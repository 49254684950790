@import '../../../assets/scss/variables';

app-pick-date {
  .content__elem {
    &.content__elem_m-right {
      margin-right: 20px;
    }
    .mat-input {
      width: 180px;
    }
  }
}

@import '../../../../assets/scss/variables';

app-teaser {
  .teaser__header {
    display: flex;
  }
  .section-title {
    width: 200px;
  }
  app-upload-image {
    .upl__image {
      width: 23px;
    }
  }
  .delete-button {
    display: flex;
    justify-content: flex-end;
  }
  .teaser__item {
    display: flex;
    flex-direction: column;
  }

  .content-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .section-title {
    font-size: 18px;
  }

  .item-label {
    width: 200px;
    min-width: 200px;
    padding-right: 30px;
    text-align: right;
  }

  .mat-form-field {
    width: 100%;
  }

}

@import '../../../../../assets/scss/variables';

app-popup-template {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 27, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .inner {
    border-radius: 5px;
    background: $white-color;
    width: 560px;
    max-width: 90vw;
    position: relative;
    .title {
      border-bottom: $default-border;
      margin-bottom: 4px;
      padding: 18px 24px;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 1;
      font-weight: 600;
      background: #F6F9FC;
      color: $blue-color4;
    }
  }
  .body {
    padding: 20px;
  }
  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 12px;
    font-weight: 600;
    top: 18px;
    z-index: 20;
    cursor: pointer;
    color: #1ac5d0;
  }
  .footer {
    margin-top: 20px;
  }
  .popup-wrapper {
    background: #fff;
    position: relative;
    border: 1px solid #c7cfd8;
    border-radius: 5px;
  }
  .lnr.default-cross {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    font-size: 24px;
    color: #8089A0;
    opacity: .7;
    border: 10px solid transparent;
  }
}

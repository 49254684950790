@import "variables";

html {
  height: 100%;
  font-family: $default-font;
}

body {
  margin: 0;
  font-family: $default-font;
  font-weight: normal;
  background: $gray-color2;
  font-size: $default-font-size;
  color: $default-font-color;
  line-height: $default-line-height-computed;
  overflow-y: scroll;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.1;
  font-weight: normal;
}

a {
  text-decoration: none;
}

input, button, textarea {
  font-family: $default-font;
  -webkit-appearance: none
}

input, select {
  font-size: 100%;
}

div {
  display: block;
}

.black-color {
  color: #02080ab3;
}

.main-view{
  background: #f3f3f4;
}
.white-color {
  color: $white-color;
}

.white-color-bg {
  background-color: $white-color;
}

.gray-color-font {
  color: $gray-color3;
}

.gray-color-base {
  background-color: $gray-color2;
}

.blue-dark-color {
  background-color: $blue-color2;
}

.gray-font-1 {
  color: $gray-color3;
}

.gray-font-4 {
  color: $gray-color4;
}

//font

.font-base {
  font-size: 14px;
}
.section__movement {
  margin: 40px 0;
}
.font-base-13 {
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
}
.filter-reset {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $blue-color11;
}
.mat-raised-button {
  background-color: #1c84c6;
}
.collectible{
  .background-item {
   label{
     width: 30px;
   }
  }
}
.mat-form-field-underline {
  //  background-color: rgba(0, 0, 0, 0.54) !important;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

// global classes


// --------CUSTOM_TOGGLE----------
.mat-slide-toggle-bar {
  height: 22px !important;
  width: 42px !important;
  border-radius: 30px !important;
  background-color: transparent !important;
  border: 2px solid #B7BCCB !important;
}

.mat-slide-toggle-thumb {
  width: 18px !important;
  height: 18px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  border-radius: 10px;
  background-color: $blue-color11 !important;
  border: 2px solid $blue-color11 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: white !important;
  width: 17px;
  border: 2px solid white !important;
  height: 17px;
}

.mat-slide-toggle-thumb {
  width: 17px;
  height: 17px;
  background-color: transparent;
  border: 2px solid #B7BCCB !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  top: 0;
  left: 3px!important;
}

.mat-slide-toggle-thumb-container {
  top: 0 !important;
  left: 2px!important;
}

// ------------------
.mat-datepicker-content {
  background-color: white;
  color: $black-color2;
}

mat-datepicker-content {
  tbody tr:hover td {
    background: transparent;
  }
  .mat-calendar-body-cell-content {
    color: $black-color2;
  }
  .mat-calendar-body-label {
    color: $black-color2;
  }
  //.mat-calendar-table-header {
  //  tr {
  //    color: $black-color2;
  //  }
  //}
  tbody td {
    border-top: none;
  }

  .mat-calendar-arrow {
    border-top-color: $black-color2;
  }
  .mat-calendar-controls {
    button {
      color: $black-color2 !important;
    }
  }

}
.star-cb-group-popup {
  /* remove inline-block whitespace */
  font-size: 0;
  /* flip the order so we can use the + and ~ combinators */
  unicode-bidi: bidi-override;
  direction: rtl;
  /* the hidden clearer */
}
.star-cb-group-popup * {
  font-size: 1rem;
}
.star-cb-group-popup > input {
  display: none;
}
.star-cb-group-popup > input + label {
  /* only enough room for the star */
  display: inline-block;
  overflow: hidden;
  text-indent: 9999px;
  width: 1em;
  white-space: nowrap;
  cursor: pointer;
  font-size: 20px;
}
.star-cb-group-popup > input + label:before {
  display: inline-block;
  text-indent: -9999px;
  content: "☆";
  color: $gray-color3;
}
.star-cb-group-popup > input:checked ~ label:before, .star-cb-group-popup > input + label:hover ~ label:before, .star-cb-group-popup > input + label:hover:before {
  content: "★";
  color: $yellow-color4;
  text-shadow: 0 0 1px #333;
}
.star-cb-group-popup > .star-cb-clear + label {
  text-indent: -9999px;
  width: .5em;
  margin-left: -.5em;
}
.star-cb-group-popup > .star-cb-clear + label:before {
  width: .5em;
}
.star-cb-group-popup:hover > input + label:before {
  content: "☆";
  color: $gray-color3;
  text-shadow: none;
}
.star-cb-group-popup:hover > input + label:hover ~ label:before, .star-cb-group-popup:hover > input + label:hover:before {
  content: "★";
  color: $yellow-color4;
  text-shadow: 0 0 1px #333;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: $white !important;
  padding: 0 .75em 0 .75em !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.65em 0 0 0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-label {
  margin-top: 0 !important;
}

.error {
  color: $red-color1;
  font-size: 12px;
}

.mat-form-field-appearance-fill._mat-animation-noopable:not(.mat-form-field-disabled) .mat-form-field-flex:hover ~ .mat-form-field-underline .mat-form-field-ripple {
  transition: none;
  background: black;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: black;
}

.calendar {
  .mat-form-field-wrapper {
    padding-bottom: 18px !important;

  }
}

.border-box-blue {
  white-space: nowrap;
  align-items: center;
  border: 2px solid $blue-color11;
  border-radius: 5px;
  color: $blue-color11;
}

.grey-bg {
  white-space: nowrap;
  background-color: $gray-color9;
  border-radius: 5px;
}

.yellow-bg {
  white-space: nowrap;
  background-color: $yellow-color2;
  border-radius: 5px;
}

.grey-bg {
  white-space: nowrap;
  background-color: $gray-color9;
  border-radius: 5px;
}

.green-bg {
  white-space: nowrap;
  background-color: $green-color1;
  border-radius: 5px;
}

.red-bg {
  white-space: nowrap;
  background-color: $red-color1;
  border-radius: 5px;
}

.green10 {
  color: $green-color10;
}

.flex {
  display: flex;
}

.flex-33 {
  width: 33.33%
}

.flex-column {
  flex-direction: column;
}

.textarea > .mat-form-field-underline {
  bottom: 1em !important;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  //  background: black !important;
}

.mat-datepicker-toggle {
  color: $gray-color4 !important;
  .mat-focus-indicator {
    color: $gray-color4;
  }
}

.mat-select-panel {
  background: white;
}

.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3;
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.upload-section {
  input[type="file"] {
    display: none;
  }
  label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }
  span {
    color: $blue-color11;
  }
  background: #EEFCFF;
  position: relative;
  border: 2px dashed $blue-color11;
  overflow: hidden;
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.small-toggle {
  .mat-slide-toggle-bar {
    height: 26px !important;
    width: 48px !important;
    border-radius: 30px;
    background-color: #5AA2E5;
    border: 2px solid #5AA2E5;
  }
  .mat-slide-toggle-thumb-container {
    transform: translate3d(46px, 0, 0);
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: transparent;
    border: 2px solid #B7BCCB;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: transparent;
    border: 2px solid #B7BCCB;
  }
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(26px, 0, 0);
  }
  .mat-slide-toggle-thumb {
    width: 20px;
    height: 20px;
  }
  .mat-slide-toggle.mat-checked .mat-ripple-element {
    background-color: transparent;
  }
  .mat-slide-toggle .mat-slide-toggle-ripple {
    top: calc(50% - 17px);
    left: calc(50% - 17px);
  }
  .mat-slide-toggle-thumb-container {
    top: 1px;
    left: -24px;
  }
}
.text-blue{
  font-weight: 500;
  font-size: 14px;
  color: $blue-color11;
  &.text-blue_pointer{
    cursor: pointer;
  }
}
.link-blue {
  font-weight: 500;
  font-size: 14px;
  color: $blue-color11;
}

.link-red {
  font-weight: 500;
  font-size: 14px;
  color: $red-color1;
}

.warning {
  color: $red-color1;
}
.success {
  color: $green-color1;
}
.social {
  .circle {
    position: relative;
    z-index: 0;
    display: block;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 28px;
      height: 28px;
      top: 0;
      left: 0;
      border-radius: 100%;
    }
  }
  &.twitter {
    position: relative;
    .circle {
      &:before {
        background-color: $blue-color8;
      }
    }
    .fa-twitter {
      position: absolute;
      font-size: 14px;
      left: 8px;
      top: 8px;
      color: #ffffff;
    }
  }
  &.linkedin {
    position: relative;
    .circle {
      &:before {
        background-color: $blue-color10;
      }
    }
    .fa-linkedin {
      position: absolute;
      font-size: 14px;
      left: 8px;
      top: 8px;
      color: #ffffff;
    }
  }
  &.pinterest {
    position: relative;
    .circle {
      &:before {
        background-color: $red-color10;
      }
    }
    .fa-pinterest-p {
      position: absolute;
      font-size: 22px;
      left: 8px;
      top: 8px;
      color: #ffffff;
    }
  }
  &.whatsapp {
    position: relative;
    .circle {
      &:before {
        background-color: $green-color12;
      }
    }
    .fa-whatsapp {
      position: absolute;
      font-size: 22px;
      left: 8px;
      top: 8px;
      color: #ffffff;
    }
  }
  &.instagram {
    position: relative;
    .circle {
      &:before {
        background-color: $red-color7;
      }
    }
    .fa-instagram {
      position: absolute;
      font-size: 19px;
      left: 6px;
      top: 4px;
      color: #ffffff;
    }
  }
  &.youtube {
    position: relative;
    .circle {
      &:before {
        background-color: $red-color8;
      }
    }
    .fa-youtube {
      position: absolute;
      font-size: 15px;
      left: 6px;
      top: 7px;
      color: #ffffff;
    }
  }
  &.dropbox {
    position: relative;
    .circle {
      &:before {
        background-color: $blue-color11;
      }
    }
    .fa-dropbox {
      position: absolute;
      font-size: 15px;
      left: 6px;
      top: 7px;
      color: #ffffff;
    }
  }
  &.soundcloud {
    position: relative;
    .circle {
      &:before {
        background-color: $yellow-color5;
      }
    }
    .fa-soundcloud {
      position: absolute;
      font-size: 15px;
      left: 4px;
      top: 6px;
      color: #ffffff;
    }
  }
  &.tiktok {
    position: relative;
    .circle {
      &:before {
        background-color: $black-color1;
      }
    }
    .fa-tiktok {
      position: absolute;
      font-size: 15px;
      left: 8px;
      top: 6px;
      color: #ffffff;
    }
  }
  &.facebook {
    position: relative;
    .circle {
      &:before {
        background-color: $blue-color9;
      }
    }
    .fa-facebook-f {
      position: absolute;
      font-size: 16px;
      left: 9px;
      top: 6px;
      color: #ffffff;
    }
  }
  &.spotify {
    position: relative;
    .fa-spotify {
      // position: absolute;
      font-size: 28px;
      //left: 9px;
      //top: 6px;
      color: $green-color11;
    }
  }
}

.spinner {
  display: flex;
  margin: 40px 0;
  align-items: center;
  justify-content: center;
}
.mat-calendar-body-selected {
  color: $white !important;
}
.flex-50{
  flex-basis: 50%;
}
.flex-25{
  flex-basis: 25%;
}
.radio-button-text_grey {
  font-size: 13px;
  font-weight: normal;
  color: $gray-color5;
}

.lnr-chevron-right {
  display: inline-block;
  font-weight: $font-weight-900;
  cursor: pointer;

  &.opened {
    transition: all .25s ease-out 0s;
    transform: rotate(90deg);
  }
}

// cross over component which has list custom
.fa-undo-alt {
  color: $red-color1;
}

.lineHeight10 {
  line-height: 1;
}

.lineHeight20 {
  line-height: 1.4;
}

.lineHeight40 {
  line-height: 1.8;
}

.lineHeight60 {
  line-height: 2.2;
}

.lineHeight80 {
  line-height: 2.6;
}

.lineHeight100 {
  line-height: 3;
}

.title-large {
  font-size: 16px;
  color: $gray-color4;
  font-weight: bold;
}

.small-text {
  font-size: 12px;
}

.text-capitalize {
  text-transform: capitalize;
}
//.header-page {
//  padding: 14px 15px;
//  border-bottom: 1px solid $gray-color1;
//  h5 {
//    @extend .font-base;
//    @extend .gray-font-4;
//    font-weight: 500;
//    margin-bottom: 0;
//  }
//}

.edit {
  cursor: pointer;
}

.text-important {
  font-size: 16px;
  font-weight: bold;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.flex-around {
  justify-content: space-around;
}

.flex-between {
  justify-content: space-between;
}

.m20-left {
  margin-left: 20px;
}

.m20-right {
  margin-right: 20px;
}

.m20-bottom {
  margin-bottom: 20px;
}

.p20-top {
  padding-top: 20px;
}

.m20-top {
  margin-top: 20px;
}

.header-inform-text {
  font-weight: 500;
  font-size: 13px;
}

.header-inform-text-grey {
  font-weight: 500;
  font-size: 13px;
  color: $gray-color5;
}



.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $blue-color11 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $blue-color11 !important;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: $gray-color3 !important;
}

//--------------mat-items design--------------------------------

.mat-item {
  width: 100%;
}

//--------------------------------------------------------------
.mat-form-field-infix {
  width: 100% !important;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.th-actions {
  width: 120px;
  max-width: 120px;
}

.table-td-160 {
  width: 160px;
  max-width: 160px;
}

.word-break {
  word-break: break-all;
}

angular2-multiselect {
  width: 100%;
  .c-btn {
    border-bottom: 1px solid #949494;
    span {
      &:first-child {
        color: #0000008a;
        margin-left: -10px;
      }
    }
  }
}

.wrapper-pagination-footer {
  margin: 20px 0;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $blue-color11 !important;
}

//.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate {
//  background: $blue-color11 !important;
//}

.lnr {
  &.disabled {
    opacity: .5;
  }

  &.lnr-warning {
    font-size: 12px;
    color: $red-color3;
    padding-right: 10px;
  }

  &.vertical-align {
    display: flex;
    align-items: center;
  }
}

.no-list-data {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}

.bottom-margin-20 {
  margin-bottom: 30px;
}

div.global-page-loader {
  position: relative;
  width: 100%;
  height: 100px;
  margin: auto;
  color: $blue-color1;

  .lnr {
    font-size: 60px;
    left: calc(50% - 30px);
    position: absolute;
    top: 0;
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.w-margin-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.loader {
  .lnr {
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes loading {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
}

.global-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 27, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  > .inner {
    border: $default-border;
    border-radius: 5px;
    background: $white-color;

    .lnr-cross {
      position: absolute;
      right: 20px;
      font-size: 12px;
      font-weight: 600;
      top: 18px;
      cursor: pointer;
      color: #1ac5d0;
    }

    > .title {
      border-bottom: $default-border;
      margin-bottom: 4px;
      padding: 18px 24px;
      font-size: 13px;
      text-transform: uppercase;
      line-height: 1;
      background: #F6F9FC;
      color: $blue-color4;
    }

    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }

  .content {
    padding: 0 24px;
    margin-top: 22px;
  }
}

.lnr {
  font-size: 15px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: none;
  }

  &:first-child {
    margin-left: 0;
  }

  &.lnr-pencil {
    color: $blue-color1;
  }

  &.lnr-file-empty {
    color: $blue-color1;
  }

  &.lnr-chevron-right {
    color: $blue-color1;
  }

  &.lnr-users {
    color: $blue-color1;
    font-size: 18px;
    font-weight: bold;
  }
  &.lnr-calendar-full {
    color: $blue-color1;
    font-size: 16px;
    font-weight: bold;
  }
  &.lnr-map-marker {
    color: $blue-color1;
    font-size: 18px;
    font-weight: bold;
  }
  &.lnr-user {
    color: $blue-color1;
    font-size: 14px;
    font-weight: bold;
  }

  &.lnr-magnifier {
    color: $green-color1;
  }

  &.lnr-checkmark-circle {
    color: $green-color1;
  }

  &.lnr-cross-circle {
    color: $red-color1;
  }

  &.lnr-trash {
    color: $red-color1;
  }

  //&.lnr-cross {
  //  color: $red-color1;
  //}

  &.disabled {
    opacity: .5;
  }
}

.in-circle {
  display: inline-block;
  border-radius: 50%;
  border: 1.7px solid;
  padding: 0.5em 0.5em;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

//******Checkbox***********//

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $blue-color1;
  border: 1px solid $blue-color1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

//****** End checkbox ***********//

///////////////__<<ngThemingMigrationEscapedComment12>>__//////////////////////
.ellipse-colored {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;

  &.ellipse-colored-1 {
    background: #C4C4C4;
  }

  &.ellipse-colored-2 {
    background: #14B6C0;
  }

  &.ellipse-colored-3 {
    background: #F2C94C;
  }

  &.ellipse-colored-4 {
    background: #82C76A;
  }

  &.ellipse-colored-5 {
    background: #EB5757;
  }

  &.ellipse-colored-6 {
    background: black;
  }
}

//****** End Colored ellipses for statuses ***********//

//max-screen

.min-screen, .mid-screen {
  display: none;
}

.max-screen {
  display: table-cell;
}

.min-mid-screen-icon {
  display: none;
}

@media screen and (max-width: 1500px) {
  //.input-text, .select {
  //  min-width: 100%;
  //}
  //.filters > .item {
  //  margin-right: 10px;
  //}
  //
  //.lnr {
  //  font-size: 13px;
  //  margin-left: 10px;
  //}
  //tr.max-screen {
  //  display: table-row;
  //}
}

//mid-screen
@media screen and (max-width: 1178px) {
  //select {
  //  line-height: 18px;
  //
  //  option {
  //    line-height: 18px;
  //  }
  //}
  //.filters {
  //  .button.reset {
  //    margin-bottom: 20px;
  //  }
  //}
  //.general-wrapper {
  //  overflow: auto;
  //  width: 100%;
  //  margin: auto;
  //
  //  &--special {
  //    width: calc(100% - 40px);
  //  }
  //
  //  table.table {
  //    margin-left: 0;
  //  }
  //}
  //
  //.min-screen, .max-screen {
  //  display: none;
  //}
  //.mid-screen {
  //  display: table-cell;
  //}
  //.max-screen-icon {
  //  display: none;
  //}
  //tr.mid-screen {
  //  display: table-row;
  //}

}

@media screen and (max-width: 767px) {

  //.action-block {
  //  right: 30px;
  //}
  //.filters {
  //  padding-bottom: 0 !important;
  //  margin-bottom: 0 !important;
  //
  //  .item {
  //    width: 100% !important;
  //    margin-right: 0 !important;
  //  }
  //}
  //.filters > .item {
  //  margin-right: 0 !important;
  //}
  //.actions {
  //  padding: 11px 0 !important;
  //  width: auto;
  //}
  //.actions-block {
  //  display: flex;
  //  flex-direction: column;
  //
  //  .lnr {
  //    font-size: 12px;
  //    margin-left: 0;
  //    margin-right: 15px;
  //    width: 30px;
  //    position: relative;
  //    padding: 0;
  //    height: 30px;
  //
  //    &:before {
  //      position: absolute;
  //      top: 7px;
  //      left: 7px;
  //    }
  //  }
  //
  //  .lnr-trash, .lnr-cross {
  //    margin-top: 12px;
  //  }
  //}
  //
  //.dashboard-content {
  //  padding: 0 15px 0;
  //}
  //.mid-screen, .max-screen {
  //  display: none;
  //}
  //.min-screen {
  //  display: table-cell;
  //}
  //.max-screen-icon {
  //  display: none;
  //}
  //tr.min-screen {
  //  display: table-row;
  //}
  //.min-mid-screen-icon {
  //  display: inline-block;
  //}

}

@media screen and (max-width: 414px) {
  //.copyright {
  //  left: 70px;
  //}
  //app-currency, app-invoices {
  //  z-index: 0;
  //}
  //.lnr:last-child {
  //  margin-left: 0;
  //}
}

.loader {
  position: relative;
  width: 30px;
  height: 30px;
  .image-load {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

.loader.hide {
  display: none;
}

@keyframes loading {
  from {
    transform: rotate(0)
  }
  to {
    transform: rotate(360deg)
  }
}

@import "assets/scss/variables";
@import "assets/scss/global";

.view {
  &__wrapper {
    margin: 15px;
    padding: 0 20px 20px;
    width: 100%;
    border-top: 3px solid $gray-color1;
    @extend .white-color-bg;
  }

  &__header {
    min-height: 48px;
    padding: 14px 15px 7px;
    border-bottom: 1px solid $gray-color1;
  }

  &__title {
    &.main-title {
      @extend .font-base;
      @extend .gray-font-4;
      margin-bottom: 0;
      font-weight: 500;
    }
  }

  &__content {
    width: 100%;
    padding: 0 25px 25px 25px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  &__image {
    width: 100%;
  }

  &__buttons {
    margin: 30px 0;
  }
}

@import '../../../assets/scss/variables';

app-pick-color {
  .color__picker {
    border: 1px solid #e7eaec;
    border-radius: 10px;
    height: 40px;
  }

}

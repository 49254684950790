@import '../../../../../assets/scss/variables';

app-popup-give-away {
  .wrapper {
    display: flex;
    align-items: center;
    mat-spinner{
      margin-left: 10px;
    }
  }
}

@import '../../../../assets/scss/variables';

:host {
  .content-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .line {
    display: flex;
    width: 100%;
    .content-item{
      width: 100%;
      &:first-child{
        padding-right: 20px;
      }
    }
  }
  textarea{
    width: 100%;
    min-width: unset;
  }
  .section-title {
    font-size: 18px;
  }

  .item-label {
    width: 200px;
    min-width: 200px;
    padding-right: 30px;
    text-align: right;
  }

  .mat-form-field {
    width: 100%;
  }
}

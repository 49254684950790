@import '../../../assets/scss/variables';
@import '../../../assets/scss/mixin';


@import 'slider/slider.component';
@import 'landing-multi-lang-text/landing-multi-lang-text.component';
@import 'card/card.component';
@import 'cards/cards.component';
@import 'block/landing-block.component';
.tab {
  margin: 20px 0;
}
.wrapper.wrapper-button{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

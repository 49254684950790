@charset "UTF-8";
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

/* RESET STYLES*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

blockquote, q {
  quotes: none;
}

:focus {
  outline: 0;
}

.angular-editor ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.angular-editor ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}
.angular-editor li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
table td, table th {
  padding: 0;
}

input[type=radio], input[type=checkbox] {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

p img.left {
  float: left;
  padding: 0;
}

p img.right {
  float: right;
}

blockquote {
  color: #666;
  font-style: italic;
}

strong {
  font-weight: 400;
}

em, dfn {
  font-style: italic;
}

sup, sub {
  line-height: 0;
}

abbr, acronym {
  border-bottom: 1px dotted #666;
}

address {
  font-style: italic;
}

del {
  color: #666;
}

a {
  text-decoration: none;
  color: #293846;
  transition: color linear 0.2s;
  cursor: pointer;
}
a:hover {
  text-decoration: underline;
  color: #030506;
  outline: none;
}
a.active, a:active {
  color: #030506;
  outline: none;
}

dl {
  margin: 0;
}

dd {
  margin-left: 0;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */
/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */
.hidden {
  display: none !important;
  visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible !important;
}

*, *:after, *:before {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-weight: normal;
  background: #f3f3f4;
  font-size: 14px;
  color: #000;
  line-height: 1.2;
  overflow-y: scroll;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.1;
  font-weight: normal;
}

a {
  text-decoration: none;
}

input, button, textarea {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-appearance: none;
}

input, select {
  font-size: 100%;
}

div {
  display: block;
}

.black-color {
  color: rgba(2, 8, 10, 0.7019607843);
}

.main-view {
  background: #f3f3f4;
}

.white-color, app-navigation .main-navigation .link.active .text, app-navigation .main-navigation .link.active .fa, app-navigation .main-navigation .link.active, app-navigation .main-navigation .link:hover .fa, app-navigation .main-navigation .link:hover .text {
  color: #fff;
}

.white-color-bg, .view__wrapper {
  background-color: #fff;
}

.gray-color-font, app-navigation .main-navigation .link, app-navigation .fa {
  color: #a7b1c2;
}

.gray-color-base {
  background-color: #f3f3f4;
}

.blue-dark-color, app-navigation {
  background-color: #2F4050;
}

.gray-font-1 {
  color: #a7b1c2;
}

.gray-font-4, app-find-by-name .search span, app-find-producer-by-name .search span, app-find-by-name-project .search span, .view__title.main-title {
  color: #676a6c;
}

.font-base, .view__title.main-title {
  font-size: 14px;
}

.section__movement {
  margin: 40px 0;
}

.font-base-13, app-find-by-name .search span, app-find-by-name .listDrpDown .autocomplete-item, app-find-producer-by-name .search span, app-find-producer-by-name .listDrpDown .autocomplete-item, app-find-by-name-project .search span, app-navigation .main-navigation .link, .table th, .table app-th-sortable {
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
}

.filter-reset {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #5AA2E5;
}

.mat-raised-button {
  background-color: #1c84c6;
}

.collectible .background-item label {
  width: 30px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-slide-toggle-bar {
  height: 22px !important;
  width: 42px !important;
  border-radius: 30px !important;
  background-color: transparent !important;
  border: 2px solid #B7BCCB !important;
}

.mat-slide-toggle-thumb {
  width: 18px !important;
  height: 18px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  border-radius: 10px;
  background-color: #5AA2E5 !important;
  border: 2px solid #5AA2E5 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: white !important;
  width: 17px;
  border: 2px solid white !important;
  height: 17px;
}

.mat-slide-toggle-thumb {
  width: 17px;
  height: 17px;
  background-color: transparent;
  border: 2px solid #B7BCCB !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  top: 0;
  left: 3px !important;
}

.mat-slide-toggle-thumb-container {
  top: 0 !important;
  left: 2px !important;
}

.mat-datepicker-content {
  background-color: white;
  color: #051B22;
}

mat-datepicker-content tbody tr:hover td {
  background: transparent;
}
mat-datepicker-content .mat-calendar-body-cell-content {
  color: #051B22;
}
mat-datepicker-content .mat-calendar-body-label {
  color: #051B22;
}
mat-datepicker-content tbody td {
  border-top: none;
}
mat-datepicker-content .mat-calendar-arrow {
  border-top-color: #051B22;
}
mat-datepicker-content .mat-calendar-controls button {
  color: #051B22 !important;
}

.star-cb-group-popup {
  /* remove inline-block whitespace */
  font-size: 0;
  /* flip the order so we can use the + and ~ combinators */
  unicode-bidi: bidi-override;
  direction: rtl;
  /* the hidden clearer */
}

.star-cb-group-popup * {
  font-size: 1rem;
}

.star-cb-group-popup > input {
  display: none;
}

.star-cb-group-popup > input + label {
  /* only enough room for the star */
  display: inline-block;
  overflow: hidden;
  text-indent: 9999px;
  width: 1em;
  white-space: nowrap;
  cursor: pointer;
  font-size: 20px;
}

.star-cb-group-popup > input + label:before {
  display: inline-block;
  text-indent: -9999px;
  content: "☆";
  color: #a7b1c2;
}

.star-cb-group-popup > input:checked ~ label:before, .star-cb-group-popup > input + label:hover ~ label:before, .star-cb-group-popup > input + label:hover:before {
  content: "★";
  color: #FFC700;
  text-shadow: 0 0 1px #333;
}

.star-cb-group-popup > .star-cb-clear + label {
  text-indent: -9999px;
  width: 0.5em;
  margin-left: -0.5em;
}

.star-cb-group-popup > .star-cb-clear + label:before {
  width: 0.5em;
}

.star-cb-group-popup:hover > input + label:before {
  content: "☆";
  color: #a7b1c2;
  text-shadow: none;
}

.star-cb-group-popup:hover > input + label:hover ~ label:before, .star-cb-group-popup:hover > input + label:hover:before {
  content: "★";
  color: #FFC700;
  text-shadow: 0 0 1px #333;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff !important;
  padding: 0 0.75em 0 0.75em !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.65em 0 0 0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-label {
  margin-top: 0 !important;
}

.error {
  color: #F95738;
  font-size: 12px;
}

.mat-form-field-appearance-fill._mat-animation-noopable:not(.mat-form-field-disabled) .mat-form-field-flex:hover ~ .mat-form-field-underline .mat-form-field-ripple {
  transition: none;
  background: black;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: black;
}

.calendar .mat-form-field-wrapper {
  padding-bottom: 18px !important;
}

.border-box-blue {
  white-space: nowrap;
  align-items: center;
  border: 2px solid #5AA2E5;
  border-radius: 5px;
  color: #5AA2E5;
}

.grey-bg {
  white-space: nowrap;
  background-color: #DFEBF4;
  border-radius: 5px;
}

.yellow-bg {
  white-space: nowrap;
  background-color: #FFA800;
  border-radius: 5px;
}

.grey-bg {
  white-space: nowrap;
  background-color: #DFEBF4;
  border-radius: 5px;
}

.green-bg {
  white-space: nowrap;
  background-color: #95C962;
  border-radius: 5px;
}

.red-bg {
  white-space: nowrap;
  background-color: #F95738;
  border-radius: 5px;
}

.green10 {
  color: #27AE60;
}

.flex {
  display: flex;
}

.flex-33 {
  width: 33.33%;
}

.flex-column {
  flex-direction: column;
}

.textarea > .mat-form-field-underline {
  bottom: 1em !important;
}

.mat-datepicker-toggle {
  color: #676a6c !important;
}
.mat-datepicker-toggle .mat-focus-indicator {
  color: #676a6c;
}

.mat-select-panel {
  background: white;
}

.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3;
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.upload-section {
  background: #EEFCFF;
  position: relative;
  border: 2px dashed #5AA2E5;
  overflow: hidden;
}
.upload-section input[type=file] {
  display: none;
}
.upload-section label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.upload-section span {
  color: #5AA2E5;
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.small-toggle .mat-slide-toggle-bar {
  height: 26px !important;
  width: 48px !important;
  border-radius: 30px;
  background-color: #5AA2E5;
  border: 2px solid #5AA2E5;
}
.small-toggle .mat-slide-toggle-thumb-container {
  transform: translate3d(46px, 0, 0);
}
.small-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: transparent;
  border: 2px solid #B7BCCB;
}
.small-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: transparent;
  border: 2px solid #B7BCCB;
}
.small-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(26px, 0, 0);
}
.small-toggle .mat-slide-toggle-thumb {
  width: 20px;
  height: 20px;
}
.small-toggle .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: transparent;
}
.small-toggle .mat-slide-toggle .mat-slide-toggle-ripple {
  top: calc(50% - 17px);
  left: calc(50% - 17px);
}
.small-toggle .mat-slide-toggle-thumb-container {
  top: 1px;
  left: -24px;
}

.text-blue {
  font-weight: 500;
  font-size: 14px;
  color: #5AA2E5;
}
.text-blue.text-blue_pointer {
  cursor: pointer;
}

.link-blue {
  font-weight: 500;
  font-size: 14px;
  color: #5AA2E5;
}

.link-red {
  font-weight: 500;
  font-size: 14px;
  color: #F95738;
}

.warning {
  color: #F95738;
}

.success {
  color: #95C962;
}

.social .circle {
  position: relative;
  z-index: 0;
  display: block;
}
.social .circle:before {
  content: "";
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  top: 0;
  left: 0;
  border-radius: 100%;
}
.social.twitter {
  position: relative;
}
.social.twitter .circle:before {
  background-color: #00d1ff;
}
.social.twitter .fa-twitter {
  position: absolute;
  font-size: 14px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.linkedin {
  position: relative;
}
.social.linkedin .circle:before {
  background-color: #0274B3;
}
.social.linkedin .fa-linkedin {
  position: absolute;
  font-size: 14px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.pinterest {
  position: relative;
}
.social.pinterest .circle:before {
  background-color: #E81B22;
}
.social.pinterest .fa-pinterest-p {
  position: absolute;
  font-size: 22px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.whatsapp {
  position: relative;
}
.social.whatsapp .circle:before {
  background-color: #25d366;
}
.social.whatsapp .fa-whatsapp {
  position: absolute;
  font-size: 22px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.instagram {
  position: relative;
}
.social.instagram .circle:before {
  background-color: #e0518d;
}
.social.instagram .fa-instagram {
  position: absolute;
  font-size: 19px;
  left: 6px;
  top: 4px;
  color: #ffffff;
}
.social.youtube {
  position: relative;
}
.social.youtube .circle:before {
  background-color: #f95738;
}
.social.youtube .fa-youtube {
  position: absolute;
  font-size: 15px;
  left: 6px;
  top: 7px;
  color: #ffffff;
}
.social.dropbox {
  position: relative;
}
.social.dropbox .circle:before {
  background-color: #5AA2E5;
}
.social.dropbox .fa-dropbox {
  position: absolute;
  font-size: 15px;
  left: 6px;
  top: 7px;
  color: #ffffff;
}
.social.soundcloud {
  position: relative;
}
.social.soundcloud .circle:before {
  background-color: #FF7A00;
}
.social.soundcloud .fa-soundcloud {
  position: absolute;
  font-size: 15px;
  left: 4px;
  top: 6px;
  color: #ffffff;
}
.social.tiktok {
  position: relative;
}
.social.tiktok .circle:before {
  background-color: #000;
}
.social.tiktok .fa-tiktok {
  position: absolute;
  font-size: 15px;
  left: 8px;
  top: 6px;
  color: #ffffff;
}
.social.facebook {
  position: relative;
}
.social.facebook .circle:before {
  background-color: #2c53b7;
}
.social.facebook .fa-facebook-f {
  position: absolute;
  font-size: 16px;
  left: 9px;
  top: 6px;
  color: #ffffff;
}
.social.spotify {
  position: relative;
}
.social.spotify .fa-spotify {
  font-size: 28px;
  color: #1ed760;
}

.spinner {
  display: flex;
  margin: 40px 0;
  align-items: center;
  justify-content: center;
}

.mat-calendar-body-selected {
  color: #fff !important;
}

.flex-50 {
  flex-basis: 50%;
}

.flex-25 {
  flex-basis: 25%;
}

.radio-button-text_grey {
  font-size: 13px;
  font-weight: normal;
  color: #555;
}

.lnr-chevron-right {
  display: inline-block;
  font-weight: 900;
  cursor: pointer;
}
.lnr-chevron-right.opened {
  transition: all 0.25s ease-out 0s;
  transform: rotate(90deg);
}

.fa-undo-alt {
  color: #F95738;
}

.lineHeight10 {
  line-height: 1;
}

.lineHeight20 {
  line-height: 1.4;
}

.lineHeight40 {
  line-height: 1.8;
}

.lineHeight60 {
  line-height: 2.2;
}

.lineHeight80 {
  line-height: 2.6;
}

.lineHeight100 {
  line-height: 3;
}

.title-large {
  font-size: 16px;
  color: #676a6c;
  font-weight: bold;
}

.small-text {
  font-size: 12px;
}

.text-capitalize {
  text-transform: capitalize;
}

.edit {
  cursor: pointer;
}

.text-important {
  font-size: 16px;
  font-weight: bold;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.flex-around {
  justify-content: space-around;
}

.flex-between {
  justify-content: space-between;
}

.m20-left {
  margin-left: 20px;
}

.m20-right {
  margin-right: 20px;
}

.m20-bottom {
  margin-bottom: 20px;
}

.p20-top {
  padding-top: 20px;
}

.m20-top {
  margin-top: 20px;
}

.header-inform-text {
  font-weight: 500;
  font-size: 13px;
}

.header-inform-text-grey {
  font-weight: 500;
  font-size: 13px;
  color: #555;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5AA2E5 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #5AA2E5 !important;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: #a7b1c2 !important;
}

.mat-item {
  width: 100%;
}

.mat-form-field-infix {
  width: 100% !important;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.th-actions {
  width: 120px;
  max-width: 120px;
}

.table-td-160 {
  width: 160px;
  max-width: 160px;
}

.word-break {
  word-break: break-all;
}

angular2-multiselect {
  width: 100%;
}
angular2-multiselect .c-btn {
  border-bottom: 1px solid #949494;
}
angular2-multiselect .c-btn span:first-child {
  color: rgba(0, 0, 0, 0.5411764706);
  margin-left: -10px;
}

.wrapper-pagination-footer {
  margin: 20px 0;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #5AA2E5 !important;
}

.lnr.disabled {
  opacity: 0.5;
}
.lnr.lnr-warning {
  font-size: 12px;
  color: #EF3E42;
  padding-right: 10px;
}
.lnr.vertical-align {
  display: flex;
  align-items: center;
}

.no-list-data {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}

.bottom-margin-20 {
  margin-bottom: 30px;
}

div.global-page-loader {
  position: relative;
  width: 100%;
  height: 100px;
  margin: auto;
  color: #293846;
}
div.global-page-loader .lnr {
  font-size: 60px;
  left: calc(50% - 30px);
  position: absolute;
  top: 0;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.w-margin-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.loader .lnr {
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.global-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 27, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.global-popup > .inner {
  border: 1px solid #949494;
  border-radius: 5px;
  background: #fff;
}
.global-popup > .inner .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 12px;
  font-weight: 600;
  top: 18px;
  cursor: pointer;
  color: #1ac5d0;
}
.global-popup > .inner > .title {
  border-bottom: 1px solid #949494;
  margin-bottom: 4px;
  padding: 18px 24px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1;
  background: #F6F9FC;
  color: #084C61;
}
.global-popup > .inner .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.global-popup .content {
  padding: 0 24px;
  margin-top: 22px;
}

.lnr {
  font-size: 15px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
}
.lnr:hover {
  text-decoration: none;
}
.lnr:first-child {
  margin-left: 0;
}
.lnr.lnr-pencil {
  color: #293846;
}
.lnr.lnr-file-empty {
  color: #293846;
}
.lnr.lnr-chevron-right {
  color: #293846;
}
.lnr.lnr-users {
  color: #293846;
  font-size: 18px;
  font-weight: bold;
}
.lnr.lnr-calendar-full {
  color: #293846;
  font-size: 16px;
  font-weight: bold;
}
.lnr.lnr-map-marker {
  color: #293846;
  font-size: 18px;
  font-weight: bold;
}
.lnr.lnr-user {
  color: #293846;
  font-size: 14px;
  font-weight: bold;
}
.lnr.lnr-magnifier {
  color: #95C962;
}
.lnr.lnr-checkmark-circle {
  color: #95C962;
}
.lnr.lnr-cross-circle {
  color: #F95738;
}
.lnr.lnr-trash {
  color: #F95738;
}
.lnr.disabled {
  opacity: 0.5;
}

.in-circle {
  display: inline-block;
  border-radius: 50%;
  border: 1.7px solid;
  padding: 0.5em 0.5em;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #293846;
  border: 1px solid #293846;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ellipse-colored {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.ellipse-colored.ellipse-colored-1 {
  background: #C4C4C4;
}
.ellipse-colored.ellipse-colored-2 {
  background: #14B6C0;
}
.ellipse-colored.ellipse-colored-3 {
  background: #F2C94C;
}
.ellipse-colored.ellipse-colored-4 {
  background: #82C76A;
}
.ellipse-colored.ellipse-colored-5 {
  background: #EB5757;
}
.ellipse-colored.ellipse-colored-6 {
  background: black;
}

.min-screen, .mid-screen {
  display: none;
}

.max-screen {
  display: table-cell;
}

.min-mid-screen-icon {
  display: none;
}

.loader {
  position: relative;
  width: 30px;
  height: 30px;
}
.loader .image-load {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loader.hide {
  display: none;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.button {
  vertical-align: top;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #949494;
  text-align: center;
  font-weight: bold;
  color: #fff;
  display: block;
  font-size: 13px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  border: none;
}
.button.large-size {
  height: 40px;
  font-size: 14px;
}
.button.default-size {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
}
.button.wide-size {
  width: 30%;
  padding: 10px 15px;
}
.button.button_default_height {
  height: 35px;
}
.button.yellow {
  border: none;
  background: #FFA800;
  color: #fff;
}
.button.yellow:hover:not(:disabled) {
  background: #e69700;
}
.button.red {
  border: none;
  background: #F95738;
  color: #fff;
}
.button.red:hover:not(:disabled) {
  background: #f8421f;
}
.button.green {
  border: none;
  background: #95C962;
  color: #fff;
}
.button.green:hover:not(:disabled) {
  background: #88c24f;
}
.button.transparent {
  background: transparent;
  color: #293846;
}
.button.blue {
  border: 2px solid #5AA2E5;
  color: #5AA2E5;
  background-color: transparent;
}
.button.blue:hover:not(:disabled) {
  border: 2px solid #4496e2;
  color: #4496e2;
}
.button.blue-bgc {
  color: #fff;
  padding: 10px 15px;
  box-sizing: border-box;
  background: #5AA2E5;
}
.button.border-blue {
  border: 2px solid #5AA2E5;
  color: #5AA2E5;
  box-sizing: border-box;
  border-radius: 5px;
  background: #fff;
}
.button.disabled {
  background: #555;
  color: #000;
  cursor: inherit;
}
.button:hover {
  text-decoration: none;
}
.button:hover .lnr, .button:hover .fas {
  background: inherit;
}
.button .lnr {
  margin-right: 8px;
}
.button.reset {
  min-width: 120px;
  margin-top: 23px;
}
.button.blue-btn {
  color: #5AA2E5;
  border: 2px solid #5AA2E5;
  background: #fff;
}
.button.blue-btn:hover {
  border: 2px solid #0F8F97;
  background: #fff;
  color: #0F8F97;
}
.button.red-btn {
  background: #F95738;
  border: 2px solid #F95738;
  color: #fff;
}
.button.red-btn:hover {
  border: 2px solid #EF3E42;
  background: #EF3E42;
  color: #fff;
}
.button:disabled {
  opacity: 0.6;
}

.form-input.form-input_right-m20 {
  margin-right: 20px;
}

.font_bold {
  font-weight: bold;
}

.bold-style {
  font-weight: bold;
}

.title-global.title-warning {
  color: #F95738;
}

.buttons-wrapper {
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-phone {
  border: 1px solid #e7eaec;
  display: block;
  height: 35px;
}

.prefix-phone {
  margin-left: 7px;
}

.form-row, .row-flex {
  display: flex;
  width: 100%;
}

.item-50 {
  width: 50%;
}

.flex-wrapper {
  display: flex;
}

.fa-trash-o {
  color: #F95738;
  font-size: 18px;
}

.fa-edit {
  color: #5AA2E5;
  font-size: 18px;
}

.fa-times-circle {
  color: #F95738;
  font-size: 18px;
}

.fa-file-alt, .fa-save {
  color: #95C962;
  font-size: 18px;
}

.text-uppercase {
  text-transform: uppercase;
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  color: #bbc4c9;
}

input::-moz-placeholder { /* Firefox 19+ */
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  color: #bbc4c9;
}

input:-ms-input-placeholder { /* IE 10+ */
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  color: #bbc4c9;
}

.form {
  padding: 0 24px;
}
.form .error .input-text, .form .error .item .input, .item .form .error .input,
.form .error .select,
.form .error .textarea,
.form .error .item-phone,
.form .error .c-btn,
.form .error .select2-selection,
.form .error .button-radio {
  border-color: #EF3E42;
}
.form .error .label, .form .error .button-radio-label {
  color: #EF3E42;
}
.form .error .input {
  border-color: #EF3E42;
}
.form .error .error-message {
  color: #EF3E42;
  font-size: 12px;
  margin-top: 5px !important;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.error-message {
  color: #EF3E42;
  font-size: 12px;
  margin: 15px 0 0 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

.textarea {
  border: 1px solid #949494;
  border-radius: 3px;
  padding: 6px 11px;
  font-size: 14px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  max-width: 100%;
  min-width: 100%;
  color: #000;
  resize: vertical;
  min-height: 90px;
  height: 180px;
}

.select {
  border: 1px solid #949494;
  border-radius: 3px;
  padding: 5px 24px 5px 0px;
  font-size: 14px;
  min-height: 35px;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  max-width: 100%;
  cursor: pointer;
  min-width: 180px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;
}
.select optgroup {
  color: #000;
}
.select option {
  color: #000;
}
.select:not([multiple]):not(.readonly) {
  background: #fff url(/assets/images/icon-select.png) no-repeat right 10px center;
}
.select[multiple] {
  height: 91px;
  padding: 0;
}
.select[multiple] option {
  padding: 5px 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.select[multiple] option:checked {
  background: linear-gradient(0deg, #c6e0a9 0%, #c6e0a9 100%);
  font-weight: bold;
}
.select[multiple] option:checked:after {
  display: inline-block;
}

.form-popup > .row > .item {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}
.form-popup > .row > .item.full {
  width: 100%;
  position: relative;
}
.form-popup > .row > .item.half {
  width: 66%;
  position: relative;
}
.form-popup > .row > .item.error {
  border-bottom-color: #F95738;
}
.form-popup > .row > .item.error .label {
  color: #F95738;
}
.form-popup > .row > .item.error .input, .form-popup > .row > .item.error app-datepicker {
  border-color: #F95738;
}
.form-popup > .row > .item.error .error-message {
  color: #F95738;
  font-size: 12px;
  margin-top: 5px !important;
}

.disabled-radio {
  opacity: 0.5;
}

.item-radio-button {
  flex-direction: row !important;
  color: #748394;
}

.input-text, .item .input {
  background-color: transparent;
  color: #000;
  height: 34px;
  border-radius: 3px;
  border: 1px solid #e7eaec;
  font-size: 14px;
  display: block;
  text-align: left;
  padding: 6px 0;
  width: auto;
  margin: 0;
}
.input-text:disabled, .item .input:disabled {
  cursor: not-allowed;
  background: #edeff2;
}
.input-text::placeholder, .item .input::placeholder {
  text-transform: capitalize;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.input-text:focus + .bottom, .item .input:focus + .bottom {
  width: 100%;
  margin-left: -50%;
}
.input-text:focus + .bottom + .label, .item .input:focus + .bottom + .label {
  transform: translateY(-19px);
}

.item .label {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 2;
  transition: all linear 0.1s;
}
.item .label.opened {
  transform: translateY(-19px);
}
.item .label.required:after {
  content: "*";
  color: #F95738;
  margin-left: 3px;
}

.mat-form-field-wrapper {
  width: 100%;
}

tbody tr.primary td, tbody tr.primary:hover td {
  background-color: #fff;
  box-shadow: 0 1px 2px #C7CFD8;
}
tbody tr:hover td {
  background: #fff;
}
tbody td {
  padding: 11px 10px;
  border-top: 1px solid #e7eaec;
  background-color: transparent;
  color: #8089A0;
  font-size: 13px;
  vertical-align: middle;
}
tbody td.warning {
  font-family: 900;
  color: #F95738;
}
tbody td.no-list-data {
  padding: 15px;
  text-align: center;
  font-family: 900;
}
tbody td.td-no-padding {
  padding: 11px 0;
}
tbody td.table-actions {
  max-width: 150px;
  width: 150px;
}

.table-vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table__actions {
  display: flex;
  justify-content: space-around;
}

.table-cell .table-cell--align {
  text-align: center;
}

.table-width-140 {
  width: 140px;
  max-width: 140px;
}

th.checkbox {
  width: 56px;
}

.table {
  width: 100%;
}
.table th, .table app-th-sortable {
  color: #676a6c;
  font-weight: 500;
  background: transparent;
  text-align: left;
  font-family: 900;
  cursor: pointer;
  vertical-align: middle;
}
.table th {
  padding: 11px 10px;
}
.table .table-sub-title {
  text-align: left;
  font-size: 11px;
  font-weight: 400;
  font-family: 900;
  cursor: pointer;
  vertical-align: top;
}
.table .success-checkmark {
  margin-left: 15px;
  border-radius: 14px;
  position: relative;
}
.table .success-checkmark:before {
  content: "";
  vertical-align: top;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  margin: -5px 0 0 -18px;
  height: 6px;
  width: 12px;
  border: solid #888;
  border-width: 0 0 2px 2px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.table .actions {
  white-space: nowrap;
  width: 1%;
  text-align: center;
}
.table .centered {
  text-align: center;
}
.table .centered .table-title {
  justify-content: center;
}
.table .checkmark {
  top: -3px;
}

.table-content {
  width: 100%;
}
.table-content thead {
  border-bottom: 1px solid #e7eaec;
}
.table-content .actions {
  display: flex;
  width: 100%;
  justify-content: center;
}
.table-content .actions .fa-pencil-alt {
  font-size: 14px;
  cursor: pointer;
  color: #5AA2E5;
}
.table-content .actions .fa-times {
  font-size: 14px;
  cursor: pointer;
  color: #8089A0;
}
.table-content tr:nth-of-type(odd) {
  background: transparent;
}
.table-content td {
  color: #8089A0;
}
.table-content th {
  font-weight: bold;
  color: #686B6D;
  padding: 8px 10px;
}
.table-content .text-break {
  word-break: break-all;
}
.table-content .status {
  font-size: 14px;
  display: flex;
  max-width: 140px;
  width: 140px;
  height: 26px;
  align-items: center;
}
.table-content .status .fa-clock, .table-content .status .fa-pause, .table-content .status .fa-user {
  padding: 0 8px;
}
.table-content .status.yellow-bg {
  color: #fff;
}
.table-content .status.red-bg {
  color: #fff;
}

.content-item-v {
  font-size: 13px;
  justify-content: space-between;
  align-items: center;
  display: flex !important;
}

.item-label-v {
  width: 20%;
  min-width: 200px;
  padding-right: 30px;
  color: #676a6c;
  font-weight: 500;
}
.item-label-v span {
  padding: 10px;
  display: inline-block;
}

.item-row {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px;
}

.item-field {
  width: 100%;
}
.item-field.item-field_align {
  display: flex;
  align-items: center;
}
.item-field.item-field_align-start {
  display: flex;
  align-items: flex-start;
}
.item-field.item-field_column {
  flex-direction: column;
}
.item-field.item-field_border-top {
  border-top: 1px dashed #676a6c;
}

.item-section {
  position: relative;
  padding-top: 20px;
}
.item-section.item-section_border-top {
  border-top: 1px dashed #676a6c;
}

.item-label {
  width: 200px;
  min-width: 200px;
  text-align: right;
}
.item-label.item-label_pad-20 {
  padding: 0 20px;
}

.content-page-blocks, .page__content {
  padding: 0;
}

.content-page-block.content-page-block_border {
  border-top: 10px solid #f3f3f4;
}
.content-page-block.content-page-block_align-c {
  text-align: center;
}

.page-text.page-text_grey-color {
  color: #676a6c;
}

.content-name {
  color: #686B6D;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  padding: 5px 0;
  border-bottom: 1px solid #E7EAEC;
}

.item-edit-v {
  display: flex !important;
  justify-content: flex-end;
}
.item-edit-v i {
  cursor: pointer;
}
.item-edit-v.item-edit_gap {
  column-gap: 20px;
}
.item-edit-v.item-edit-v_width {
  width: 5%;
}

.item-value-v {
  width: 75%;
  color: #8089A0;
}
.item-value-v.item-value-v_pad {
  padding-right: 20px;
}

l-select2 {
  display: inline-block;
  width: 100%;
  position: relative;
  cursor: pointer;
}
l-select2:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: block;
}
l-select2.error .select2-container--default .select2-selection--multiple {
  border-color: #F95738;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 33px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #949494;
  border-radius: 4px;
  cursor: text;
}

.filters .select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #949494;
  border-radius: 20px;
  cursor: text;
  min-height: 30px !important;
}

.filters .active .select2-container--default .select2-selection--multiple {
  border: 2px solid #293846;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
  cursor: pointer;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 3px;
  padding: 0 30px 0 10px;
  line-height: 24px;
  position: relative;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice > span,
.select2-container--classic .select2-selection--multiple .select2-selection__choice > span {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 9px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder, .select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #949494;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #293846;
  color: #fff;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
  font-family: "open_sansregular", sans-serif;
  font-size: 12px;
  line-height: normal;
  font-weight: 400;
  padding-top: 4px;
  padding-bottom: 4px;
}

.select2-container--default .select2-results__option[aria-disabled=true] {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  line-height: normal;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #000;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-family: "open_sansregular", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: -o-linear-gradient(top, #fff 50%, #eee 100%);
  background-image: linear-gradient(to bottom, #fff 50%, #eee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #ccc 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: -o-linear-gradient(top, #fff 0%, #eee 50%);
  background-image: linear-gradient(to bottom, #fff 0%, #eee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: -o-linear-gradient(top, #eee 50%, #fff 100%);
  background-image: linear-gradient(to bottom, #eee 50%, #fff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: #fff;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

html {
  height: 100%;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-weight: normal;
  background: #f3f3f4;
  font-size: 14px;
  color: #000;
  line-height: 1.2;
  overflow-y: scroll;
  height: 100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.1;
  font-weight: normal;
}

a {
  text-decoration: none;
}

input, button, textarea {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-appearance: none;
}

input, select {
  font-size: 100%;
}

div {
  display: block;
}

.black-color {
  color: rgba(2, 8, 10, 0.7019607843);
}

.main-view {
  background: #f3f3f4;
}

.white-color, app-navigation .main-navigation .link.active .text, app-navigation .main-navigation .link.active .fa, app-navigation .main-navigation .link.active, app-navigation .main-navigation .link:hover .fa, app-navigation .main-navigation .link:hover .text {
  color: #fff;
}

.white-color-bg, .view__wrapper {
  background-color: #fff;
}

.gray-color-font, app-navigation .main-navigation .link, app-navigation .fa {
  color: #a7b1c2;
}

.gray-color-base {
  background-color: #f3f3f4;
}

.blue-dark-color, app-navigation {
  background-color: #2F4050;
}

.gray-font-1 {
  color: #a7b1c2;
}

.gray-font-4, app-find-by-name .search span, app-find-producer-by-name .search span, app-find-by-name-project .search span, .view__title.main-title {
  color: #676a6c;
}

.font-base, .view__title.main-title {
  font-size: 14px;
}

.section__movement {
  margin: 40px 0;
}

.font-base-13, app-find-by-name .search span, app-find-by-name .listDrpDown .autocomplete-item, app-find-producer-by-name .search span, app-find-producer-by-name .listDrpDown .autocomplete-item, app-find-by-name-project .search span, app-navigation .main-navigation .link, .table th, .table app-th-sortable {
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
}

.filter-reset {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #5AA2E5;
}

.mat-raised-button {
  background-color: #1c84c6;
}

.collectible .background-item label {
  width: 30px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-slide-toggle-bar {
  height: 22px !important;
  width: 42px !important;
  border-radius: 30px !important;
  background-color: transparent !important;
  border: 2px solid #B7BCCB !important;
}

.mat-slide-toggle-thumb {
  width: 18px !important;
  height: 18px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  border-radius: 10px;
  background-color: #5AA2E5 !important;
  border: 2px solid #5AA2E5 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: white !important;
  width: 17px;
  border: 2px solid white !important;
  height: 17px;
}

.mat-slide-toggle-thumb {
  width: 17px;
  height: 17px;
  background-color: transparent;
  border: 2px solid #B7BCCB !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  top: 0;
  left: 3px !important;
}

.mat-slide-toggle-thumb-container {
  top: 0 !important;
  left: 2px !important;
}

.mat-datepicker-content {
  background-color: white;
  color: #051B22;
}

mat-datepicker-content tbody tr:hover td {
  background: transparent;
}
mat-datepicker-content .mat-calendar-body-cell-content {
  color: #051B22;
}
mat-datepicker-content .mat-calendar-body-label {
  color: #051B22;
}
mat-datepicker-content tbody td {
  border-top: none;
}
mat-datepicker-content .mat-calendar-arrow {
  border-top-color: #051B22;
}
mat-datepicker-content .mat-calendar-controls button {
  color: #051B22 !important;
}

.star-cb-group-popup {
  /* remove inline-block whitespace */
  font-size: 0;
  /* flip the order so we can use the + and ~ combinators */
  unicode-bidi: bidi-override;
  direction: rtl;
  /* the hidden clearer */
}

.star-cb-group-popup * {
  font-size: 1rem;
}

.star-cb-group-popup > input {
  display: none;
}

.star-cb-group-popup > input + label {
  /* only enough room for the star */
  display: inline-block;
  overflow: hidden;
  text-indent: 9999px;
  width: 1em;
  white-space: nowrap;
  cursor: pointer;
  font-size: 20px;
}

.star-cb-group-popup > input + label:before {
  display: inline-block;
  text-indent: -9999px;
  content: "☆";
  color: #a7b1c2;
}

.star-cb-group-popup > input:checked ~ label:before, .star-cb-group-popup > input + label:hover ~ label:before, .star-cb-group-popup > input + label:hover:before {
  content: "★";
  color: #FFC700;
  text-shadow: 0 0 1px #333;
}

.star-cb-group-popup > .star-cb-clear + label {
  text-indent: -9999px;
  width: 0.5em;
  margin-left: -0.5em;
}

.star-cb-group-popup > .star-cb-clear + label:before {
  width: 0.5em;
}

.star-cb-group-popup:hover > input + label:before {
  content: "☆";
  color: #a7b1c2;
  text-shadow: none;
}

.star-cb-group-popup:hover > input + label:hover ~ label:before, .star-cb-group-popup:hover > input + label:hover:before {
  content: "★";
  color: #FFC700;
  text-shadow: 0 0 1px #333;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #fff !important;
  padding: 0 0.75em 0 0.75em !important;
}

.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.65em 0 0 0 !important;
}

.mat-form-field-appearance-fill .mat-form-field-label {
  margin-top: 0 !important;
}

.error {
  color: #F95738;
  font-size: 12px;
}

.mat-form-field-appearance-fill._mat-animation-noopable:not(.mat-form-field-disabled) .mat-form-field-flex:hover ~ .mat-form-field-underline .mat-form-field-ripple {
  transition: none;
  background: black;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: black;
}

.calendar .mat-form-field-wrapper {
  padding-bottom: 18px !important;
}

.border-box-blue {
  white-space: nowrap;
  align-items: center;
  border: 2px solid #5AA2E5;
  border-radius: 5px;
  color: #5AA2E5;
}

.grey-bg {
  white-space: nowrap;
  background-color: #DFEBF4;
  border-radius: 5px;
}

.yellow-bg {
  white-space: nowrap;
  background-color: #FFA800;
  border-radius: 5px;
}

.grey-bg {
  white-space: nowrap;
  background-color: #DFEBF4;
  border-radius: 5px;
}

.green-bg {
  white-space: nowrap;
  background-color: #95C962;
  border-radius: 5px;
}

.red-bg {
  white-space: nowrap;
  background-color: #F95738;
  border-radius: 5px;
}

.green10 {
  color: #27AE60;
}

.flex {
  display: flex;
}

.flex-33 {
  width: 33.33%;
}

.flex-column {
  flex-direction: column;
}

.textarea > .mat-form-field-underline {
  bottom: 1em !important;
}

.mat-datepicker-toggle {
  color: #676a6c !important;
}
.mat-datepicker-toggle .mat-focus-indicator {
  color: #676a6c;
}

.mat-select-panel {
  background: white;
}

.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #2196f3;
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}

.upload-section {
  background: #EEFCFF;
  position: relative;
  border: 2px dashed #5AA2E5;
  overflow: hidden;
}
.upload-section input[type=file] {
  display: none;
}
.upload-section label {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}
.upload-section span {
  color: #5AA2E5;
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.small-toggle .mat-slide-toggle-bar {
  height: 26px !important;
  width: 48px !important;
  border-radius: 30px;
  background-color: #5AA2E5;
  border: 2px solid #5AA2E5;
}
.small-toggle .mat-slide-toggle-thumb-container {
  transform: translate3d(46px, 0, 0);
}
.small-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: transparent;
  border: 2px solid #B7BCCB;
}
.small-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: transparent;
  border: 2px solid #B7BCCB;
}
.small-toggle .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(26px, 0, 0);
}
.small-toggle .mat-slide-toggle-thumb {
  width: 20px;
  height: 20px;
}
.small-toggle .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: transparent;
}
.small-toggle .mat-slide-toggle .mat-slide-toggle-ripple {
  top: calc(50% - 17px);
  left: calc(50% - 17px);
}
.small-toggle .mat-slide-toggle-thumb-container {
  top: 1px;
  left: -24px;
}

.text-blue {
  font-weight: 500;
  font-size: 14px;
  color: #5AA2E5;
}
.text-blue.text-blue_pointer {
  cursor: pointer;
}

.link-blue {
  font-weight: 500;
  font-size: 14px;
  color: #5AA2E5;
}

.link-red {
  font-weight: 500;
  font-size: 14px;
  color: #F95738;
}

.warning {
  color: #F95738;
}

.success {
  color: #95C962;
}

.social .circle {
  position: relative;
  z-index: 0;
  display: block;
}
.social .circle:before {
  content: "";
  display: block;
  position: absolute;
  width: 28px;
  height: 28px;
  top: 0;
  left: 0;
  border-radius: 100%;
}
.social.twitter {
  position: relative;
}
.social.twitter .circle:before {
  background-color: #00d1ff;
}
.social.twitter .fa-twitter {
  position: absolute;
  font-size: 14px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.linkedin {
  position: relative;
}
.social.linkedin .circle:before {
  background-color: #0274B3;
}
.social.linkedin .fa-linkedin {
  position: absolute;
  font-size: 14px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.pinterest {
  position: relative;
}
.social.pinterest .circle:before {
  background-color: #E81B22;
}
.social.pinterest .fa-pinterest-p {
  position: absolute;
  font-size: 22px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.whatsapp {
  position: relative;
}
.social.whatsapp .circle:before {
  background-color: #25d366;
}
.social.whatsapp .fa-whatsapp {
  position: absolute;
  font-size: 22px;
  left: 8px;
  top: 8px;
  color: #ffffff;
}
.social.instagram {
  position: relative;
}
.social.instagram .circle:before {
  background-color: #e0518d;
}
.social.instagram .fa-instagram {
  position: absolute;
  font-size: 19px;
  left: 6px;
  top: 4px;
  color: #ffffff;
}
.social.youtube {
  position: relative;
}
.social.youtube .circle:before {
  background-color: #f95738;
}
.social.youtube .fa-youtube {
  position: absolute;
  font-size: 15px;
  left: 6px;
  top: 7px;
  color: #ffffff;
}
.social.dropbox {
  position: relative;
}
.social.dropbox .circle:before {
  background-color: #5AA2E5;
}
.social.dropbox .fa-dropbox {
  position: absolute;
  font-size: 15px;
  left: 6px;
  top: 7px;
  color: #ffffff;
}
.social.soundcloud {
  position: relative;
}
.social.soundcloud .circle:before {
  background-color: #FF7A00;
}
.social.soundcloud .fa-soundcloud {
  position: absolute;
  font-size: 15px;
  left: 4px;
  top: 6px;
  color: #ffffff;
}
.social.tiktok {
  position: relative;
}
.social.tiktok .circle:before {
  background-color: #000;
}
.social.tiktok .fa-tiktok {
  position: absolute;
  font-size: 15px;
  left: 8px;
  top: 6px;
  color: #ffffff;
}
.social.facebook {
  position: relative;
}
.social.facebook .circle:before {
  background-color: #2c53b7;
}
.social.facebook .fa-facebook-f {
  position: absolute;
  font-size: 16px;
  left: 9px;
  top: 6px;
  color: #ffffff;
}
.social.spotify {
  position: relative;
}
.social.spotify .fa-spotify {
  font-size: 28px;
  color: #1ed760;
}

.spinner {
  display: flex;
  margin: 40px 0;
  align-items: center;
  justify-content: center;
}

.mat-calendar-body-selected {
  color: #fff !important;
}

.flex-50 {
  flex-basis: 50%;
}

.flex-25 {
  flex-basis: 25%;
}

.radio-button-text_grey {
  font-size: 13px;
  font-weight: normal;
  color: #555;
}

.lnr-chevron-right {
  display: inline-block;
  font-weight: 900;
  cursor: pointer;
}
.lnr-chevron-right.opened {
  transition: all 0.25s ease-out 0s;
  transform: rotate(90deg);
}

.fa-undo-alt {
  color: #F95738;
}

.lineHeight10 {
  line-height: 1;
}

.lineHeight20 {
  line-height: 1.4;
}

.lineHeight40 {
  line-height: 1.8;
}

.lineHeight60 {
  line-height: 2.2;
}

.lineHeight80 {
  line-height: 2.6;
}

.lineHeight100 {
  line-height: 3;
}

.title-large {
  font-size: 16px;
  color: #676a6c;
  font-weight: bold;
}

.small-text {
  font-size: 12px;
}

.text-capitalize {
  text-transform: capitalize;
}

.edit {
  cursor: pointer;
}

.text-important {
  font-size: 16px;
  font-weight: bold;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

.flex-around {
  justify-content: space-around;
}

.flex-between {
  justify-content: space-between;
}

.m20-left {
  margin-left: 20px;
}

.m20-right {
  margin-right: 20px;
}

.m20-bottom {
  margin-bottom: 20px;
}

.p20-top {
  padding-top: 20px;
}

.m20-top {
  margin-top: 20px;
}

.header-inform-text {
  font-weight: 500;
  font-size: 13px;
}

.header-inform-text-grey {
  font-weight: 500;
  font-size: 13px;
  color: #555;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5AA2E5 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #5AA2E5 !important;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  color: #a7b1c2 !important;
}

.mat-item {
  width: 100%;
}

.mat-form-field-infix {
  width: 100% !important;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.th-actions {
  width: 120px;
  max-width: 120px;
}

.table-td-160 {
  width: 160px;
  max-width: 160px;
}

.word-break {
  word-break: break-all;
}

angular2-multiselect {
  width: 100%;
}
angular2-multiselect .c-btn {
  border-bottom: 1px solid #949494;
}
angular2-multiselect .c-btn span:first-child {
  color: rgba(0, 0, 0, 0.5411764706);
  margin-left: -10px;
}

.wrapper-pagination-footer {
  margin: 20px 0;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #5AA2E5 !important;
}

.lnr.disabled {
  opacity: 0.5;
}
.lnr.lnr-warning {
  font-size: 12px;
  color: #EF3E42;
  padding-right: 10px;
}
.lnr.vertical-align {
  display: flex;
  align-items: center;
}

.no-list-data {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 !important;
}

.bottom-margin-20 {
  margin-bottom: 30px;
}

div.global-page-loader {
  position: relative;
  width: 100%;
  height: 100px;
  margin: auto;
  color: #293846;
}
div.global-page-loader .lnr {
  font-size: 60px;
  left: calc(50% - 30px);
  position: absolute;
  top: 0;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.w-margin-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.loader .lnr {
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.global-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 27, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.global-popup > .inner {
  border: 1px solid #949494;
  border-radius: 5px;
  background: #fff;
}
.global-popup > .inner .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 12px;
  font-weight: 600;
  top: 18px;
  cursor: pointer;
  color: #1ac5d0;
}
.global-popup > .inner > .title {
  border-bottom: 1px solid #949494;
  margin-bottom: 4px;
  padding: 18px 24px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1;
  background: #F6F9FC;
  color: #084C61;
}
.global-popup > .inner .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.global-popup .content {
  padding: 0 24px;
  margin-top: 22px;
}

.lnr {
  font-size: 15px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
}
.lnr:hover {
  text-decoration: none;
}
.lnr:first-child {
  margin-left: 0;
}
.lnr.lnr-pencil {
  color: #293846;
}
.lnr.lnr-file-empty {
  color: #293846;
}
.lnr.lnr-chevron-right {
  color: #293846;
}
.lnr.lnr-users {
  color: #293846;
  font-size: 18px;
  font-weight: bold;
}
.lnr.lnr-calendar-full {
  color: #293846;
  font-size: 16px;
  font-weight: bold;
}
.lnr.lnr-map-marker {
  color: #293846;
  font-size: 18px;
  font-weight: bold;
}
.lnr.lnr-user {
  color: #293846;
  font-size: 14px;
  font-weight: bold;
}
.lnr.lnr-magnifier {
  color: #95C962;
}
.lnr.lnr-checkmark-circle {
  color: #95C962;
}
.lnr.lnr-cross-circle {
  color: #F95738;
}
.lnr.lnr-trash {
  color: #F95738;
}
.lnr.disabled {
  opacity: 0.5;
}

.in-circle {
  display: inline-block;
  border-radius: 50%;
  border: 1.7px solid;
  padding: 0.5em 0.5em;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: #293846;
  border: 1px solid #293846;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ellipse-colored {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.ellipse-colored.ellipse-colored-1 {
  background: #C4C4C4;
}
.ellipse-colored.ellipse-colored-2 {
  background: #14B6C0;
}
.ellipse-colored.ellipse-colored-3 {
  background: #F2C94C;
}
.ellipse-colored.ellipse-colored-4 {
  background: #82C76A;
}
.ellipse-colored.ellipse-colored-5 {
  background: #EB5757;
}
.ellipse-colored.ellipse-colored-6 {
  background: black;
}

.min-screen, .mid-screen {
  display: none;
}

.max-screen {
  display: table-cell;
}

.min-mid-screen-icon {
  display: none;
}

.loader {
  position: relative;
  width: 30px;
  height: 30px;
}
.loader .image-load {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loader.hide {
  display: none;
}

@keyframes loading {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.view__wrapper {
  margin: 15px;
  padding: 0 20px 20px;
  width: 100%;
  border-top: 3px solid #e7eaec;
}
.view__header {
  min-height: 48px;
  padding: 14px 15px 7px;
  border-bottom: 1px solid #e7eaec;
}
.view__title.main-title {
  margin-bottom: 0;
  font-weight: 500;
}
.view__content {
  width: 100%;
  padding: 0 25px 25px 25px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.view__image {
  width: 100%;
}
.view__buttons {
  margin: 30px 0;
}

@font-face {
  font-family: "Linearicons-Free";
  src: url("../fonts/linearicons/Linearicons-Free.eot?w118d");
  src: url("../fonts/linearicons/Linearicons-Free.eot?#iefixw118d") format("embedded-opentype"), url("../fonts/linearicons/Linearicons-Free.woff2?w118d") format("woff2"), url("../fonts/linearicons/Linearicons-Free.woff?w118d") format("woff"), url("../fonts/linearicons/Linearicons-Free.ttf?w118d") format("truetype"), url("../fonts/linearicons/Linearicons-Free.svg?w118d#Linearicons-Free") format("svg");
  font-weight: normal;
  font-style: normal;
}
.lnr {
  font-family: "Linearicons-Free";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lnr-home:before {
  content: "\e800";
}

.lnr-apartment:before {
  content: "\e801";
}

.lnr-pencil:before {
  content: "\e802";
}

.lnr-magic-wand:before {
  content: "\e803";
}

.lnr-drop:before {
  content: "\e804";
}

.lnr-lighter:before {
  content: "\e805";
}

.lnr-poop:before {
  content: "\e806";
}

.lnr-sun:before {
  content: "\e807";
}

.lnr-moon:before {
  content: "\e808";
}

.lnr-cloud:before {
  content: "\e809";
}

.lnr-cloud-upload:before {
  content: "\e80a";
}

.lnr-cloud-download:before {
  content: "\e80b";
}

.lnr-cloud-sync:before {
  content: "\e80c";
}

.lnr-cloud-check:before {
  content: "\e80d";
}

.lnr-database:before {
  content: "\e80e";
}

.lnr-lock:before {
  content: "\e80f";
}

.lnr-cog:before {
  content: "\e810";
}

.lnr-trash:before {
  content: "\e811";
}

.lnr-dice:before {
  content: "\e812";
}

.lnr-heart:before {
  content: "\e813";
}

.lnr-star:before {
  content: "\e814";
}

.lnr-star-half:before {
  content: "\e815";
}

.lnr-star-empty:before {
  content: "\e816";
}

.lnr-flag:before {
  content: "\e817";
}

.lnr-envelope:before {
  content: "\e818";
}

.lnr-paperclip:before {
  content: "\e819";
}

.lnr-inbox:before {
  content: "\e81a";
}

.lnr-eye:before {
  content: "\e81b";
}

.lnr-printer:before {
  content: "\e81c";
}

.lnr-file-empty:before {
  content: "\e81d";
}

.lnr-file-add:before {
  content: "\e81e";
}

.lnr-enter:before {
  content: "\e81f";
}

.lnr-exit:before {
  content: "\e820";
}

.lnr-graduation-hat:before {
  content: "\e821";
}

.lnr-license:before {
  content: "\e822";
}

.lnr-music-note:before {
  content: "\e823";
}

.lnr-film-play:before {
  content: "\e824";
}

.lnr-camera-video:before {
  content: "\e825";
}

.lnr-camera:before {
  content: "\e826";
}

.lnr-picture:before {
  content: "\e827";
}

.lnr-book:before {
  content: "\e828";
}

.lnr-bookmark:before {
  content: "\e829";
}

.lnr-user:before {
  content: "\e82a";
}

.lnr-users:before {
  content: "\e82b";
}

.lnr-shirt:before {
  content: "\e82c";
}

.lnr-store:before {
  content: "\e82d";
}

.lnr-cart:before {
  content: "\e82e";
}

.lnr-tag:before {
  content: "\e82f";
}

.lnr-phone-handset:before {
  content: "\e830";
}

.lnr-phone:before {
  content: "\e831";
}

.lnr-pushpin:before {
  content: "\e832";
}

.lnr-map-marker:before {
  content: "\e833";
}

.lnr-map:before {
  content: "\e834";
}

.lnr-location:before {
  content: "\e835";
}

.lnr-calendar-full:before {
  content: "\e836";
}

.lnr-keyboard:before {
  content: "\e837";
}

.lnr-spell-check:before {
  content: "\e838";
}

.lnr-screen:before {
  content: "\e839";
}

.lnr-smartphone:before {
  content: "\e83a";
}

.lnr-tablet:before {
  content: "\e83b";
}

.lnr-laptop:before {
  content: "\e83c";
}

.lnr-laptop-phone:before {
  content: "\e83d";
}

.lnr-power-switch:before {
  content: "\e83e";
}

.lnr-bubble:before {
  content: "\e83f";
}

.lnr-heart-pulse:before {
  content: "\e840";
}

.lnr-construction:before {
  content: "\e841";
}

.lnr-pie-chart:before {
  content: "\e842";
}

.lnr-chart-bars:before {
  content: "\e843";
}

.lnr-gift:before {
  content: "\e844";
}

.lnr-diamond:before {
  content: "\e845";
}

.lnr-linearicons:before {
  content: "\e846";
}

.lnr-dinner:before {
  content: "\e847";
}

.lnr-coffee-cup:before {
  content: "\e848";
}

.lnr-leaf:before {
  content: "\e849";
}

.lnr-paw:before {
  content: "\e84a";
}

.lnr-rocket:before {
  content: "\e84b";
}

.lnr-briefcase:before {
  content: "\e84c";
}

.lnr-bus:before {
  content: "\e84d";
}

.lnr-car:before {
  content: "\e84e";
}

.lnr-train:before {
  content: "\e84f";
}

.lnr-bicycle:before {
  content: "\e850";
}

.lnr-wheelchair:before {
  content: "\e851";
}

.lnr-select:before {
  content: "\e852";
}

.lnr-earth:before {
  content: "\e853";
}

.lnr-smile:before {
  content: "\e854";
}

.lnr-sad:before {
  content: "\e855";
}

.lnr-neutral:before {
  content: "\e856";
}

.lnr-mustache:before {
  content: "\e857";
}

.lnr-alarm:before {
  content: "\e858";
}

.lnr-bullhorn:before {
  content: "\e859";
}

.lnr-volume-high:before {
  content: "\e85a";
}

.lnr-volume-medium:before {
  content: "\e85b";
}

.lnr-volume-low:before {
  content: "\e85c";
}

.lnr-volume:before {
  content: "\e85d";
}

.lnr-mic:before {
  content: "\e85e";
}

.lnr-hourglass:before {
  content: "\e85f";
}

.lnr-undo:before {
  content: "\e860";
}

.lnr-redo:before {
  content: "\e861";
}

.lnr-sync:before {
  content: "\e862";
}

.lnr-history:before {
  content: "\e863";
}

.lnr-clock:before {
  content: "\e864";
}

.lnr-download:before {
  content: "\e865";
}

.lnr-upload:before {
  content: "\e866";
}

.lnr-enter-down:before {
  content: "\e867";
}

.lnr-exit-up:before {
  content: "\e868";
}

.lnr-bug:before {
  content: "\e869";
}

.lnr-code:before {
  content: "\e86a";
}

.lnr-link:before {
  content: "\e86b";
}

.lnr-unlink:before {
  content: "\e86c";
}

.lnr-thumbs-up:before {
  content: "\e86d";
}

.lnr-thumbs-down:before {
  content: "\e86e";
}

.lnr-magnifier:before {
  content: "\e86f";
}

.lnr-cross:before {
  content: "\e870";
}

.lnr-menu:before {
  content: "\e871";
}

.lnr-list:before {
  content: "\e872";
}

.lnr-chevron-up:before {
  content: "\e873";
}

.lnr-chevron-down:before {
  content: "\e874";
}

.lnr-chevron-left:before {
  content: "\e875";
}

.lnr-chevron-right:before {
  content: "\e876";
}

.lnr-arrow-up:before {
  content: "\e877";
}

.lnr-arrow-down:before {
  content: "\e878";
}

.lnr-arrow-left:before {
  content: "\e879";
}

.lnr-arrow-right:before {
  content: "\e87a";
}

.lnr-move:before {
  content: "\e87b";
}

.lnr-warning:before {
  content: "\e87c";
}

.lnr-question-circle:before {
  content: "\e87d";
}

.lnr-menu-circle:before {
  content: "\e87e";
}

.lnr-checkmark-circle:before {
  content: "\e87f";
}

.lnr-cross-circle:before {
  content: "\e880";
}

.lnr-plus-circle:before {
  content: "\e881";
}

.lnr-circle-minus:before {
  content: "\e882";
}

.lnr-arrow-up-circle:before {
  content: "\e883";
}

.lnr-arrow-down-circle:before {
  content: "\e884";
}

.lnr-arrow-left-circle:before {
  content: "\e885";
}

.lnr-arrow-right-circle:before {
  content: "\e886";
}

.lnr-chevron-up-circle:before {
  content: "\e887";
}

.lnr-chevron-down-circle:before {
  content: "\e888";
}

.lnr-chevron-left-circle:before {
  content: "\e889";
}

.lnr-chevron-right-circle:before {
  content: "\e88a";
}

.lnr-crop:before {
  content: "\e88b";
}

.lnr-frame-expand:before {
  content: "\e88c";
}

.lnr-frame-contract:before {
  content: "\e88d";
}

.lnr-layers:before {
  content: "\e88e";
}

.lnr-funnel:before {
  content: "\e88f";
}

.lnr-text-format:before {
  content: "\e890";
}

.lnr-text-format-remove:before {
  content: "\e891";
}

.lnr-text-size:before {
  content: "\e892";
}

.lnr-bold:before {
  content: "\e893";
}

.lnr-italic:before {
  content: "\e894";
}

.lnr-underline:before {
  content: "\e895";
}

.lnr-strikethrough:before {
  content: "\e896";
}

.lnr-highlight:before {
  content: "\e897";
}

.lnr-text-align-left:before {
  content: "\e898";
}

.lnr-text-align-center:before {
  content: "\e899";
}

.lnr-text-align-right:before {
  content: "\e89a";
}

.lnr-text-align-justify:before {
  content: "\e89b";
}

.lnr-line-spacing:before {
  content: "\e89c";
}

.lnr-indent-increase:before {
  content: "\e89d";
}

.lnr-indent-decrease:before {
  content: "\e89e";
}

.lnr-pilcrow:before {
  content: "\e89f";
}

.lnr-direction-ltr:before {
  content: "\e8a0";
}

.lnr-direction-rtl:before {
  content: "\e8a1";
}

.lnr-page-break:before {
  content: "\e8a2";
}

.lnr-sort-alpha-asc:before {
  content: "\e8a3";
}

.lnr-sort-amount-asc:before {
  content: "\e8a4";
}

.lnr-hand:before {
  content: "\e8a5";
}

.lnr-pointer-up:before {
  content: "\e8a6";
}

.lnr-pointer-right:before {
  content: "\e8a7";
}

.lnr-pointer-down:before {
  content: "\e8a8";
}

.lnr-pointer-left:before {
  content: "\e8a9";
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
:host {
  width: 100%;
  display: block;
  z-index: 20;
  position: sticky;
  top: 0;
  left: 0;
}
:host .header {
  padding: 0 16px;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #e7eaec;
  background-color: #fff;
}
:host .header .navigation {
  width: 60%;
  flex: 1;
  padding-left: 42px;
  display: flex;
  justify-content: flex-start;
}
:host .header .login {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
:host .header .login span {
  display: inline-block;
  padding-right: 20px;
  font-weight: 500;
  font-size: 14px;
}
:host .sing-in {
  cursor: pointer;
}

app-navigation {
  z-index: 1;
  position: relative;
  top: 0;
  bottom: 0;
  transition: all 200ms ease-in;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 250px 0 0;
}
app-navigation .nav-header {
  display: flex;
  height: 60px;
  justify-content: flex-start;
}
app-navigation .nav-header img {
  width: 150px;
  height: 18px;
  position: absolute;
  right: 20px;
  top: 22px;
}
app-navigation .nav-header .menu-toggle-wrapper {
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
}
app-navigation .nav-header .menu-toggle-wrapper .menu-toggle {
  display: inline-block;
  font-size: 24px;
  width: 28px;
  color: #fff;
  font-weight: normal;
}
app-navigation .nav-header .menu-toggle-wrapper .menu-toggle:hover, app-navigation .nav-header .menu-toggle-wrapper .menu-toggle:active {
  text-decoration: none;
}
app-navigation .sub-wrapper {
  height: 0;
  transition: height ease-in-out 0.2s;
  overflow: hidden;
}
app-navigation .client.opened {
  height: auto;
}
app-navigation .main-navigation {
  padding-top: 40px;
}
app-navigation .main-navigation .link {
  padding: 14px 20px 14px 25px;
  position: relative;
  line-height: 1;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  align-items: center;
  border-left: 4px solid transparent;
  justify-content: flex-start;
}
app-navigation .main-navigation .link .icon {
  width: 75px;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
app-navigation .main-navigation .link .text {
  white-space: nowrap;
  line-height: 16px;
  margin-left: 20px;
}
app-navigation .main-navigation .link .notifications {
  color: #fff;
  margin-left: 10px;
}
app-navigation .main-navigation .link:hover {
  background: #293846;
  text-decoration: none;
}
app-navigation .main-navigation .link.active {
  background: #293846;
  text-decoration: none;
  border-left: 4px solid #95C962;
}
app-navigation .main-navigation .link .icon {
  display: inline-block;
}
app-navigation .sub {
  padding-left: 55px;
  height: 40px !important;
}
app-navigation .sub .text {
  padding-left: 20px;
}
app-navigation .lnr-chevron-right {
  color: #e7eaec;
  font-size: 10px;
  font-weight: 600;
  transition: all linear 0.2s;
}
app-navigation .lnr-chevron-right.rotate {
  transform: rotate(90deg);
}
app-navigation.collapsed {
  overflow-y: inherit;
  flex: 70px 0 0;
}
app-navigation.collapsed .link {
  width: 100%;
}
app-navigation.collapsed .link .text {
  display: none;
}
app-navigation.collapsed .link.active {
  width: 100% !important;
}
app-navigation.collapsed:hover {
  overflow-x: hidden;
}
app-navigation.collapsed .lnr-chevron-right {
  display: none;
}
app-navigation.collapsed > .logo {
  height: 60px;
  width: 30px;
  margin-left: 11px;
  justify-content: flex-start;
}
app-navigation.collapsed > .logo .img {
  height: 35px;
}
app-navigation.collapsed > .logo .text {
  display: none;
}
app-navigation.collapsed .main-navigation .link .text {
  display: none;
}
app-navigation.collapsed .main-navigation .link .icon {
  width: 53px;
}
app-navigation .navigation-loader {
  margin: 50px auto;
}
app-navigation .navigation-loader .lnr {
  font-size: 50px;
}

app-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 27, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
app-popup > .inner {
  border: 1px solid #949494;
  border-radius: 5px;
  background: #fff;
}
app-popup > .inner .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 12px;
  font-weight: 600;
  top: 18px;
  cursor: pointer;
  color: #1ac5d0;
}
app-popup > .inner > .title {
  border-bottom: 1px solid #949494;
  margin-bottom: 4px;
  padding: 18px 24px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1;
  background: #F6F9FC;
  color: #084C61;
}
app-popup > .inner .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
app-popup .content {
  padding: 0 24px;
  margin-top: 22px;
}
app-popup .popup-wrapper {
  background: #fff;
  position: relative;
  border: 1px solid #c7cfd8;
  border-radius: 5px;
}
app-popup .popup-wrapper .title {
  border-bottom: 1px solid #949494;
  margin-bottom: 4px;
  padding: 18px 24px;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 600;
}
app-popup .popup-wrapper .row {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
app-popup .lnr.default-cross {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  color: #8089A0;
  opacity: 0.7;
  border: 10px solid transparent;
}

@media screen and (max-width: 768px) {
  app-popup .inner {
    width: 100% !important;
    max-height: 90% !important;
    overflow: auto !important;
  }
  app-popup .form > .row {
    display: block;
  }
  app-popup .form > .row > .item {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  app-popup .popup-wrapper {
    width: 90%;
    max-height: 650px;
    overflow-y: scroll;
    top: 10px;
  }
  app-popup .inner {
    width: 100% !important;
  }
}
app-advances-popup .popup-wrapper .title {
  margin-bottom: 10px;
}
app-advances-popup .inner {
  max-width: 500px;
  width: 90vw;
  position: relative;
  padding: 0 !important;
}
app-advances-popup .buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}
app-advances-popup .buttons-wrapper .button {
  max-width: 45%;
  width: 45%;
}
app-advances-popup .form-row {
  justify-content: space-between;
}
app-advances-popup .recoupable-rights {
  display: flex;
  justify-content: flex-end;
}
app-advances-popup .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #293846;
}
app-advances-popup .form {
  padding: 0 30px 30px;
}
app-advances-popup .item {
  width: 50%;
  margin: 5px 0;
  max-width: 206px;
}
app-advances-popup .calendar .mat-form-field-flex {
  padding: 0;
}
app-advances-popup .calendar .mat-form-field-infix {
  padding: 0;
}
app-advances-popup mat-radio-button {
  margin-right: 15px;
}
app-advances-popup .mat-checkbox-frame {
  border-color: #000;
}
app-advances-popup .label {
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  text-transform: capitalize;
  font-size: 14px;
  color: #f3f3f4;
  line-height: 2;
  transition: all linear 0.1s;
}
app-advances-popup .label.opened {
  transform: translateY(-19px);
}
app-advances-popup .error-message.align-block {
  display: flex;
}
app-advances-popup .error-message .error-message-text {
  display: inline-block;
}
app-advances-popup .select[multiple] {
  min-height: 80px;
}

app-popup-confirmation .inner {
  width: 420px;
  max-width: 90vw;
  position: relative;
  padding: 30px;
}
app-popup-confirmation .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #293846;
}
app-popup-confirmation .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-popup-confirmation .button-wrapper {
  justify-content: space-between;
}
app-popup-confirmation .text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px;
}
app-popup-confirmation .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-popup-confirmation .button {
  justify-content: center;
  width: 100%;
  max-width: 170px;
}
app-popup-confirmation mat-form-field {
  width: 100%;
}
app-popup-confirmation .fa-envelope {
  color: #293846;
  margin: 0 20px 0 12px;
}

app-popup-upload-revenue .inner {
  width: 560px;
  max-width: 90vw;
  position: relative;
}
app-popup-upload-revenue .date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
app-popup-upload-revenue .content {
  padding: 0 30px 30px 30px;
}
app-popup-upload-revenue .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #293846;
}
app-popup-upload-revenue .list-wrapper {
  display: flex;
  flex-wrap: wrap;
}
app-popup-upload-revenue .list-wrapper .item {
  padding: 0 10px;
  width: 100%;
  max-width: 100%;
}
app-popup-upload-revenue .matches {
  margin-bottom: 20px;
}
app-popup-upload-revenue .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-popup-upload-revenue .summary .item {
  max-width: 100%;
  text-align: left;
  margin-bottom: 10px;
}
app-popup-upload-revenue .list {
  max-height: 300px;
  overflow-y: scroll;
}
app-popup-upload-revenue .text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px;
}
app-popup-upload-revenue .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-popup-upload-revenue .button-wrapper {
  width: 100%;
  justify-content: space-between;
}
app-popup-upload-revenue .fa-envelope {
  color: #293846;
  margin: 0 20px 0 12px;
}
app-popup-upload-revenue .button {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  app-cookies-popup .inner {
    padding: 40px 24px;
  }
  app-cookies-popup .form {
    padding: 0;
  }
  app-cookies-popup .button {
    width: 100%;
  }
}
app-stars-readonly .checked {
  color: #FFC700;
}
app-stars-readonly span {
  padding: 0 1px;
}

app-popup-template {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 27, 34, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
app-popup-template .inner {
  border-radius: 5px;
  background: #fff;
  width: 560px;
  max-width: 90vw;
  position: relative;
}
app-popup-template .inner .title {
  border-bottom: 1px solid #949494;
  margin-bottom: 4px;
  padding: 18px 24px;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 600;
  background: #F6F9FC;
  color: #084C61;
}
app-popup-template .body {
  padding: 20px;
}
app-popup-template .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 12px;
  font-weight: 600;
  top: 18px;
  z-index: 20;
  cursor: pointer;
  color: #1ac5d0;
}
app-popup-template .footer {
  margin-top: 20px;
}
app-popup-template .popup-wrapper {
  background: #fff;
  position: relative;
  border: 1px solid #c7cfd8;
  border-radius: 5px;
}
app-popup-template .lnr.default-cross {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 24px;
  color: #8089A0;
  opacity: 0.7;
  border: 10px solid transparent;
}

app-datepicker {
  position: relative;
  cursor: pointer;
  display: block;
  z-index: 9;
  border: 1px solid #e7eaec;
  height: 34px;
  border-radius: 3px;
}
app-datepicker.opened {
  z-index: 11;
}
app-datepicker.opened .calendar-wrapper {
  display: block;
  padding-bottom: 10px;
}
app-datepicker.to-top .calendar-wrapper {
  top: auto;
  bottom: 29px;
  border-radius: 5px 5px 0 0;
}
app-datepicker.to-right .calendar-wrapper {
  left: auto;
  right: 0;
}
app-datepicker .input-text, app-datepicker .item .input, .item app-datepicker .input {
  padding-right: 30px;
  width: 100%;
  height: 100%;
}
app-datepicker .lnr-calendar-full {
  position: absolute;
  top: 8px;
  right: 10px;
  color: #293846;
  cursor: pointer;
}
app-datepicker .input-text-empty {
  cursor: pointer;
}
app-datepicker .calendar-wrapper {
  position: absolute;
  top: 29px;
  left: 0;
  display: none;
  background: #fff;
  border: 1px solid #949494;
  border-radius: 0 0 5px 5px;
}
app-datepicker .calendar-wrapper .header {
  padding: 20px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
app-datepicker .calendar-wrapper .header .title {
  line-height: 1;
  text-transform: uppercase;
  font-size: 14px;
  background: transparent !important;
  border: none !important;
  cursor: pointer;
  min-width: auto !important;
  color: #293846;
  text-decoration: underline;
}
app-datepicker .calendar-wrapper .header .lnr {
  cursor: pointer;
  font-size: 14px;
  color: #293846;
  margin-left: 0;
}
app-datepicker .calendar-wrapper .header .lnr.disabled {
  opacity: 0.5;
  cursor: default;
}
app-datepicker .calendar-wrapper .date-row {
  padding: 0 20px 2px !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
  width: 280px !important;
}
app-datepicker .calendar-wrapper .date-row.row-wrapped {
  flex-wrap: wrap;
  width: 280px;
}
app-datepicker .calendar-wrapper .date-row > .item {
  width: 34px !important;
  height: 34px !important;
  line-height: 32px;
  font-size: 14px;
  margin-right: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  text-align: center;
  cursor: default;
}
app-datepicker .calendar-wrapper .date-row > .item:not(.disabled) {
  cursor: pointer;
}
app-datepicker .calendar-wrapper .date-row > .item.active {
  background: #2F4050;
}
app-datepicker .calendar-wrapper .date-row > .item.activeLast {
  background: #2F4050;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}
app-datepicker .calendar-wrapper .date-row > .item.activeStart {
  background: #2F4050;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
app-datepicker .calendar-wrapper .date-row > .item.last, app-datepicker .calendar-wrapper .date-row > .item.disabled {
  color: #f3f3f4;
}
app-datepicker .calendar-wrapper .date-row > .item.current {
  border: 1px solid #949494;
  border-radius: 3px;
}
app-datepicker .calendar-wrapper .date-row > .item.month {
  width: 60px !important;
  height: 60px !important;
  line-height: 60px;
  cursor: pointer;
}
app-datepicker .calendar-wrapper .date-row > .item.month:hover {
  background: #2F4050;
}
app-datepicker .calendar-helper {
  margin: 10px 20px 30px;
  background: #676a6c;
  border: 1px solid #949494;
  border-radius: 5px;
  padding: 15px 20px;
}
app-datepicker .calendar-helper .item {
  position: relative;
  padding-left: 25px;
}
app-datepicker .calendar-helper .item:before {
  content: "";
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
  width: 8px;
  height: 0;
  border-radius: 1px;
}
app-datepicker .calendar-helper .amr {
  margin-bottom: 10px;
}
app-datepicker .calendar-helper .amr:before {
  border-top: 2px solid #F95738;
  border-bottom: 1px solid #F95738;
}
app-datepicker .calendar-helper .determination:before {
  border-top: 2px solid #95C962;
  border-bottom: 1px solid #95C962;
}
app-datepicker .row.day-title {
  border-bottom: 1px solid #949494;
  padding-bottom: 0;
  margin: -10px 0 10px;
}

app-sliders .button {
  padding: 10px 15px;
}

app-teaser .teaser__header {
  display: flex;
}
app-teaser .section-title {
  width: 200px;
}
app-teaser app-upload-image .upl__image {
  width: 23px;
}
app-teaser .delete-button {
  display: flex;
  justify-content: flex-end;
}
app-teaser .teaser__item {
  display: flex;
  flex-direction: column;
}
app-teaser .content-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
app-teaser .section-title {
  font-size: 18px;
}
app-teaser .item-label {
  width: 200px;
  min-width: 200px;
  padding-right: 30px;
  text-align: right;
}
app-teaser .mat-form-field {
  width: 100%;
}

app-upload-document {
  display: flex;
  align-items: center;
}
app-upload-document .upload-button {
  height: 35px;
  padding: 0 15px;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  display: flex;
  background: #5AA2E5;
  margin-right: 10px;
}
app-upload-document .upload-button .desktop-upload {
  visibility: hidden;
  width: 0;
}
app-upload-document .upload-button.disabledUpload {
  opacity: 0.6;
}

app-pick-color .color__picker {
  border: 1px solid #e7eaec;
  border-radius: 10px;
  height: 40px;
}

app-pick-date .content__elem.content__elem_m-right {
  margin-right: 20px;
}
app-pick-date .content__elem .mat-input {
  width: 180px;
}

app-find-user-statement-upload .search__elem.search__elem_m-right {
  margin-right: 20px;
}
app-find-user-statement-upload .search__elem .mat-input {
  width: 180px;
}
app-find-user-statement-upload .search__elem.search__field {
  display: flex;
}
app-find-user-statement-upload .button {
  height: 35px;
  margin-left: 20px;
}
app-find-user-statement-upload .content__row {
  font-size: 13px;
  display: flex;
}
app-find-user-statement-upload .content__row.content__row_flex-end {
  justify-content: flex-start;
}

app-find-by-name-universal {
  width: 100%;
  position: relative;
}
app-find-by-name-universal mat-form-field {
  width: 100%;
}
app-find-by-name-universal .search-field {
  width: 100%;
  position: relative;
  height: 40px;
}
app-find-by-name-universal input {
  padding: 0 !important;
}

app-biography-multiple {
  display: block;
  margin: 20px 0;
}
app-biography-multiple .content-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
app-biography-multiple .remove-b {
  margin: 20px 0;
}
app-biography-multiple .tab-item {
  margin-bottom: 20px;
}
app-biography-multiple form {
  display: block;
  width: 100%;
}
app-biography-multiple .section-title {
  font-size: 18px;
}
app-biography-multiple .artist {
  margin: 25px 0;
}
app-biography-multiple .item-label {
  width: 200px;
  min-width: 200px;
  padding: 0 20px;
  text-align: right;
}

app-find-by-name-project {
  width: 100%;
  position: relative;
}
app-find-by-name-project .listDrpDown {
  position: absolute;
  width: 100%;
  height: auto;
  top: 60px;
  z-index: 50;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  max-height: 200px;
  overflow-y: scroll;
}
app-find-by-name-project .listDrpDown .autocomplete-item {
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  margin: 0;
  padding: 5px 10px;
  color: #555;
  cursor: pointer;
}
app-find-by-name-project .listDrpDown .autocomplete-item:hover {
  background-color: #f3f3f4;
}
app-find-by-name-project .listDrpDown .autocomplete-item__song {
  padding-left: 15px;
}
app-find-by-name-project .listDrpDown .autocomplete-item__title {
  padding: 5px;
}
app-find-by-name-project .listDrpDown.songs > li {
  margin-bottom: 5px;
}
app-find-by-name-project mat-form-field {
  width: 100%;
}
app-find-by-name-project .trash {
  position: absolute;
  bottom: 10px;
  right: 0;
  cursor: pointer;
}
app-find-by-name-project .search {
  width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  white-space: nowrap;
}
app-find-by-name-project .search span {
  display: inline-block;
  margin-right: 20px;
  font-weight: 600;
}
app-find-by-name-project .search .warning {
  color: #F95738;
  font-size: 11px;
}
app-find-by-name-project .search-field {
  width: 100%;
  position: relative;
  height: 40px;
}
app-find-by-name-project input {
  padding: 0 !important;
}

app-find-producer-by-name {
  width: 100%;
  position: relative;
  display: block;
}
app-find-producer-by-name .listDrpDown {
  position: absolute;
  width: 100%;
  height: auto;
  top: 45px;
  z-index: 50;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  max-height: 200px;
  overflow-y: scroll;
}
app-find-producer-by-name .listDrpDown .autocomplete-item {
  padding: 5px 10px;
  color: #555;
  cursor: pointer;
}
app-find-producer-by-name .listDrpDown .autocomplete-item:hover {
  background-color: #f3f3f4;
}
app-find-producer-by-name mat-form-field {
  width: 100%;
}
app-find-producer-by-name mat-form-field:has(input[readonly]) {
  opacity: 0.5;
}
app-find-producer-by-name .search {
  width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  white-space: nowrap;
}
app-find-producer-by-name .search span {
  display: inline-block;
  margin-right: 20px;
  font-weight: 600;
}
app-find-producer-by-name .search .warning {
  color: #F95738;
  font-size: 11px;
}
app-find-producer-by-name .search .search-field {
  width: 100%;
  position: relative;
  height: 40px;
}
app-find-producer-by-name input {
  padding: 0 !important;
}

app-add-multi-input .item-label {
  width: 100%;
  text-align: left;
  margin: 20px 0;
}
app-add-multi-input .add {
  color: #5AA2E5;
  font-weight: bold;
  cursor: pointer;
}
app-add-multi-input .list-controls {
  position: relative;
}
app-add-multi-input .remove-control {
  position: absolute;
  top: 12px;
  right: 0;
  cursor: pointer;
}

app-release-revenue .checked {
  color: #FFC700;
}
app-release-revenue .upload-button {
  width: 120px;
  height: 35px;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  display: flex;
  background: #5AA2E5;
}
app-release-revenue .upload-button .desktop-upload {
  visibility: hidden;
  width: 0;
}
app-release-revenue .col-button .button {
  margin: 0 auto !important;
}
app-release-revenue .item-album {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
app-release-revenue .table .button {
  margin: 0;
}
app-release-revenue .url {
  font-size: 12px;
  line-height: 1.2;
}
app-release-revenue .item {
  max-width: 120px;
  margin: 0 auto;
  min-width: 120px;
}
app-release-revenue .item .content-item {
  width: 100%;
}
app-release-revenue .item.item-song--height {
  height: 53px;
}
app-release-revenue .actions {
  width: 100% !important;
  display: flex;
  justify-content: center;
}
app-release-revenue .actions {
  display: flex;
}
app-release-revenue .actions .actions-icon {
  cursor: pointer;
  padding: 0 5px;
}
app-release-revenue .table-title {
  font-size: 18px;
  padding-right: 30px;
}
app-release-revenue .add-revenue {
  align-items: center;
}

:host {
  display: block;
  margin: 20px 0;
}
:host .row {
  display: flex;
  flex-direction: column;
}
:host .item-edit-row {
  width: 100%;
}
:host .item-select {
  margin-right: 20px;
  min-width: 288px;
  width: 40%;
}
:host .share__value {
  width: 180px;
}
:host form {
  display: flex;
  width: 100%;
}

app-read-more-section .content-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
app-read-more-section .section-title {
  font-size: 18px;
}
app-read-more-section .item-label {
  width: 200px;
  min-width: 200px;
  padding: 0 20px;
  text-align: right;
}

:host .content-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
:host .line {
  display: flex;
  width: 100%;
}
:host .line .content-item {
  width: 100%;
}
:host .line .content-item:first-child {
  padding-right: 20px;
}
:host textarea {
  width: 100%;
  min-width: unset;
}
:host .section-title {
  font-size: 18px;
}
:host .item-label {
  width: 200px;
  min-width: 200px;
  padding-right: 30px;
  text-align: right;
}
:host .mat-form-field {
  width: 100%;
}

app-popup-collectible-auction .inner {
  width: 560px;
  max-width: 90vw;
  position: relative;
}
app-popup-collectible-auction .content {
  padding: 0 30px 30px 30px;
}
app-popup-collectible-auction .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #293846;
}
app-popup-collectible-auction .start-auction__button {
  width: 150px;
  height: 43px;
}
app-popup-collectible-auction .date .auction__param {
  margin: 20px 0 0;
}
app-popup-collectible-auction .date #item-hour {
  width: 100px;
}
app-popup-collectible-auction .date .date-end {
  display: flex;
  justify-content: space-between;
}
app-popup-collectible-auction .date .date-time {
  display: flex;
  align-items: flex-end;
}
app-popup-collectible-auction .date .date-points {
  margin: 15px 10px;
}
app-popup-collectible-auction .text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px;
}
app-popup-collectible-auction #collectible .button-wrapper {
  width: 100%;
  margin-top: 20px;
  justify-content: space-between;
}
app-popup-collectible-auction #collectible .button {
  font-size: 16px;
}

app-find-by-name {
  width: 100%;
  position: relative;
}
app-find-by-name .listDrpDown {
  position: absolute;
  width: 100%;
  height: auto;
  top: 45px;
  z-index: 50;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background: #ffffff;
  max-height: 200px;
  overflow-y: scroll;
}
app-find-by-name .listDrpDown .autocomplete-item {
  padding: 5px 10px;
  color: #555;
  cursor: pointer;
}
app-find-by-name .listDrpDown .autocomplete-item:hover {
  background-color: #f3f3f4;
}
app-find-by-name mat-form-field {
  width: 100%;
}
app-find-by-name .search {
  width: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  white-space: nowrap;
}
app-find-by-name .search span {
  display: inline-block;
  margin-right: 20px;
  font-weight: 600;
}
app-find-by-name .search .warning {
  color: #F95738;
  font-size: 11px;
}
app-find-by-name .search .search-field {
  width: 100%;
  position: relative;
  height: 40px;
}
app-find-by-name input {
  padding: 0 !important;
}

app-upload-video {
  width: 100%;
}
app-upload-video .upload-video {
  display: flex;
  align-items: center;
}
app-upload-video .upload-video .mat-spinner {
  margin-left: 10px;
}
app-upload-video .file-name {
  cursor: pointer;
}
app-upload-video .upl__image {
  width: 100%;
}
app-upload-video .name {
  padding-left: 10px;
  font-size: 13px;
  color: #555;
}
app-upload-video .upload-section {
  width: 100%;
  height: 80px;
}
app-upload-video .upload-section label {
  flex-direction: row;
}
app-upload-video .upload-section .fa-music, app-upload-video .upload-section .fa-camera, app-upload-video .upload-section .fa-video {
  font-size: 20px;
  padding-right: 18px;
  color: #5AA2E5;
}
app-upload-video .upload-section img {
  width: 100%;
  position: absolute;
  left: 0;
  text-align: center;
  top: 0;
}

app-upload-image {
  display: block;
  width: 100%;
  max-width: 400px;
}
app-upload-image .upload__image-wrapper {
  margin-top: 20px;
}
app-upload-image .upl__image {
  width: 100%;
}
app-upload-image .hide {
  visibility: hidden;
}
app-upload-image .upl__name {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #555;
}
app-upload-image .fa-times-circle {
  position: relative;
  cursor: pointer;
  padding-right: 15px;
}
app-upload-image .change-picture {
  position: relative;
  overflow: hidden;
  padding: 10px 0;
}
app-upload-image .change-picture.button {
  max-width: 150px;
}
app-upload-image .change-picture input {
  position: absolute;
  padding: 15px 10px;
  height: 0;
  left: 0;
  top: 5px;
  width: 120px;
  opacity: 0;
  cursor: pointer;
}

app-popup-generate-voucher .inner {
  width: 420px;
  max-width: 90vw;
  position: relative;
  padding: 30px;
}
app-popup-generate-voucher .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #293846;
}
app-popup-generate-voucher .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-popup-generate-voucher .text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px;
}
app-popup-generate-voucher .content {
  text-align: center;
}
app-popup-generate-voucher .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-popup-generate-voucher mat-form-field {
  width: 100%;
}
app-popup-generate-voucher .fa-envelope {
  color: #293846;
  margin: 0 20px 0 12px;
}
app-popup-generate-voucher .button {
  max-width: 170px;
  width: 100%;
  margin: 0 5px;
  font-size: 16px;
}
app-popup-generate-voucher .button-wrapper {
  justify-content: center;
}

app-popup-deliver-revenue .inner {
  width: 560px;
  max-width: 90vw;
  position: relative;
}
app-popup-deliver-revenue .content {
  padding: 0 30px 30px 30px;
}
app-popup-deliver-revenue .lnr-cross {
  position: absolute;
  right: 20px;
  font-size: 16px;
  font-weight: 600;
  top: 20px;
  cursor: pointer;
  color: #293846;
}
app-popup-deliver-revenue .list-wrapper {
  display: flex;
  flex-wrap: wrap;
}
app-popup-deliver-revenue .list-wrapper .item {
  padding: 0 10px;
  width: 100%;
  max-width: 100%;
}
app-popup-deliver-revenue .matches {
  margin-bottom: 20px;
}
app-popup-deliver-revenue .block-title {
  font-weight: 900;
  font-size: 21px;
  text-transform: uppercase;
  text-align: center;
  margin: 30px 0 10px;
}
app-popup-deliver-revenue .summary .item {
  max-width: 100%;
  text-align: left;
  margin-bottom: 10px;
}
app-popup-deliver-revenue .list {
  max-height: 300px;
  overflow-y: scroll;
}
app-popup-deliver-revenue .text {
  font-size: 15px;
  text-align: center;
  margin-bottom: 30px;
}
app-popup-deliver-revenue .image {
  display: block;
  margin: 0 auto;
  height: 130px;
}
app-popup-deliver-revenue .button-wrapper {
  width: 100%;
  justify-content: space-between;
}
app-popup-deliver-revenue .fa-envelope {
  color: #293846;
  margin: 0 20px 0 12px;
}
app-popup-deliver-revenue .button {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  app-cookies-popup .inner {
    padding: 40px 24px;
  }
  app-cookies-popup .form {
    padding: 0;
  }
  app-cookies-popup .button {
    width: 100%;
  }
}
app-popup-give-away .wrapper {
  display: flex;
  align-items: center;
}
app-popup-give-away .wrapper mat-spinner {
  margin-left: 10px;
}

app-social-links-song .social-item {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
app-social-links-song .social-item .wrapper-link {
  display: flex;
  width: 100%;
  padding-left: 5px;
  justify-content: space-between;
}
app-social-links-song .social-item .wrapper-link .edit {
  color: #5AA2E5;
  cursor: pointer;
}
app-social-links-song .social-item .wrapper-link .link {
  height: 44px;
  line-height: 28px;
}
app-social-links-song .social-item .wrapper-link .link a {
  color: #5AA2E5;
}
app-social-links-song .social-item .social-item-icon {
  width: 10%;
  position: relative;
}
app-social-links-song .social-item .social-item-url {
  width: 90%;
  color: #8089A0;
}
app-social-links-song .social-item .social-item-url mat-form-field {
  width: 100%;
}
app-social-links-song .social-item .social.youtube, app-social-links-song .social-item .social.soundcloud, app-social-links-song .social-item .social.dropbox, app-social-links-song .social-item .social.instagram, app-social-links-song .social-item .social.tiktok, app-social-links-song .social-item .social.spotify {
  top: -24px;
  position: absolute;
}
app-social-links-song .social-item .remove-control {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  color: #F95738;
}
app-social-links-song .social-item .mat-form-field-flex {
  width: 90%;
}
app-social-links-song .social-item .save-control {
  right: 25px;
  position: absolute;
  top: 0;
  cursor: pointer;
  color: #95C962;
}

app-submission-fields .list-item {
  display: flex;
  position: relative;
  justify-content: space-between;
}
app-submission-fields .list-item .list-controls {
  position: relative;
}
app-submission-fields .list-item .add {
  padding-right: 30px;
  padding-top: 20px;
  cursor: pointer;
  color: #5AA2E5;
}
app-submission-fields .list-item .fa-times-circle {
  color: #F95738;
}
app-submission-fields .list-item .remove-control {
  cursor: pointer;
  position: absolute;
  right: -20px;
  top: 10px;
}
app-submission-fields .list-item .mat-item {
  width: 380px;
}
app-submission-fields .item-edit {
  width: 100%;
}
app-submission-fields .mat-item {
  width: 100%;
}
app-submission-fields .find-user .find-user__wrapper {
  width: 100%;
}
app-submission-fields .find-user .add {
  white-space: nowrap;
}
app-submission-fields .mat-form-field-infix {
  border-top: 0.84375em solid transparent;
}
app-submission-fields .wrapper-content {
  margin: 0 34px;
}
app-submission-fields .item-document {
  margin-bottom: 20px;
}
app-submission-fields .dialog-footer {
  padding: 20px 34px;
}
app-submission-fields .upload-section {
  width: 100%;
  height: 150px;
}
app-submission-fields .upload-section label {
  flex-direction: row;
}
app-submission-fields .upload-section .fa-music, app-submission-fields .upload-section .fa-camera {
  font-size: 20px;
  padding-right: 18px;
  color: #5AA2E5;
}
app-submission-fields .genres span {
  margin-right: 10px;
}
app-submission-fields .file-name {
  font-weight: 600;
  display: inline-block;
  margin: 10px 0;
}
app-submission-fields .file-name .icon-cancel {
  padding-right: 10px;
}
app-submission-fields .file-name .fa-times-circle {
  color: #F95738;
  cursor: pointer;
}
app-submission-fields .footer-content {
  padding: 0 !important;
}
app-submission-fields .footer-content .footer-item:first-child {
  width: 10%;
  color: #8089A0;
}
app-submission-fields .footer-content .footer-item:last-child {
  width: 90%;
}

app-upload-file {
  display: block;
}
app-upload-file .wrapper {
  display: flex;
}
app-upload-file .upload-button {
  height: 35px;
  padding: 0 15px;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  display: flex;
  background: #5AA2E5;
}
app-upload-file .upload-button .desktop-upload {
  visibility: hidden;
  width: 0;
}
app-upload-file .upload-button.disabledUpload {
  opacity: 0.6;
}

app-image-cropper .desktop-upload {
  display: none;
}
app-image-cropper .crop__upload {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-image-cropper .button {
  padding: 10px 0;
  width: 150px;
}

app-text-block .desktop-upload {
  display: none;
}
app-text-block .crop__upload {
  display: flex;
  align-items: center;
  justify-content: center;
}
app-text-block .button {
  padding: 10px 0;
  width: 150px;
}

app-slider-component .tab {
  margin: 20px 0;
}

app-free-text .tab {
  margin: 20px 0;
}

app-card-component .icon {
  margin: 0 10px 10px 0;
  font-size: 20px;
  padding: 5px;
  border: 2px solid transparent;
  cursor: pointer;
  color: #676a6c;
}
app-card-component app-upload-image .upl__image {
  width: 50%;
}
app-card-component [type=radio]:checked + span {
  outline: 2px solid #f00;
}

app-cards .tab {
  margin: 20px 0;
}
app-cards .list-cards {
  border-bottom: 1px solid #e7eaec;
  padding-bottom: 20px;
}

app-landing-block app-upload-image .upl__image {
  width: 50%;
}

.tab {
  margin: 20px 0;
}

.wrapper.wrapper-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

:host .item-label {
  width: 200px;
  min-width: 200px;
  padding-right: 30px;
  margin-top: 12px;
  text-align: right;
  font-weight: 500;
  font-size: 13px;
  color: #555;
}

:host .content-item {
  display: flex;
  margin-bottom: 10px;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #00bcd4;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5722;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #00bcd4;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5722;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #00bcd4;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #e91e63;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5722;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #00bcd4;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #e91e63;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #ff5722;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #00bcd4;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #e91e63;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5722;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #00bcd4;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #e91e63;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #ff5722;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #00bcd4;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e91e63;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5722;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #00bcd4;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #e91e63;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5722;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #00bcd4;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5722;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #e91e63;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 188, 212, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 188, 212, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 188, 212, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #00bcd4;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 188, 212, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 188, 212, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 188, 212, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(233, 30, 99, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(233, 30, 99, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 87, 34, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5722;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 87, 34, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 87, 34, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 87, 34, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #00bcd4;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #e91e63;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #ff5722;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #00bcd4;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #e91e63;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5722;
}

.mat-focused .mat-form-field-required-marker {
  color: #e91e63;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #00bcd4;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #e91e63;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5722;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #00bcd4;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #e91e63;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5722;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5722;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5722;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5722;
}

.mat-error {
  color: #ff5722;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #00bcd4;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #e91e63;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5722;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5722;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #00bcd4;
}
.mat-icon.mat-accent {
  color: #e91e63;
}
.mat-icon.mat-warn {
  color: #ff5722;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #00bcd4;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #e91e63;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #ff5722;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5722;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #bcebf1;
}

.mat-progress-bar-buffer {
  background-color: #bcebf1;
}

.mat-progress-bar-fill::after {
  background-color: #00bcd4;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f6c3d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f6c3d4;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #e91e63;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #fbd1c4;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #fbd1c4;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5722;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #00bcd4;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #e91e63;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #ff5722;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #00bcd4;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #00bcd4;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5722;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5722;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #00bcd4;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #e91e63;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5722;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5722;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #00bcd4;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 188, 212, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #00bcd4;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5722;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 87, 34, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5722;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #00bcd4;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 188, 212, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #e91e63;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5722;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 87, 34, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #00bcd4;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5722;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5722;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5722;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 235, 242, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #00bcd4;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(136, 14, 79, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #e91e63;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5722;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 235, 242, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #00bcd4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(136, 14, 79, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #e91e63;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5722;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #00bcd4;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #e91e63;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #ff5722;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #e91e63;
}
@import '../../../../../assets/scss/variables';

app-popup-upload-revenue {
  .inner {
    width: 560px;
    max-width: 90vw;
    position: relative;

  }
  .date {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content {
    padding: 0 30px 30px 30px;
  }
  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }
  .list-wrapper {
    display: flex;
    flex-wrap: wrap;
    .item {
      padding: 0 10px;
      width: 100%;
      max-width: 100%;
    }
  }

  .matches {
    margin-bottom: 20px;
  }
  .block-title {
    font-weight: 900;
    font-size: 21px;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 10px;
  }
  .summary {

    .item {
      max-width: 100%;
      text-align: left;
      margin-bottom: 10px;
    }
  }
  .list {
    max-height: 300px;
    overflow-y: scroll;
  }
  .text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }

  .image {
    display: block;
    margin: 0 auto;
    height: 130px;
  }
  .button-wrapper {
    width: 100%;
    justify-content: space-between;
  }
  .fa-envelope {
    color: $link-color;
    margin: 0 20px 0 12px;
  }

  .button {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  app-cookies-popup {
    .inner {
      padding: 40px 24px;
    }

    .form {
      padding: 0;
    }
    .button {
      width: 100%;
    }
  }
}

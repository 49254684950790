@import '../../../../assets/scss/variables';

app-stars-readonly {
  .checked {
    color: $yellow-color4;
  }
  span{
    padding: 0 1px;
  }
}

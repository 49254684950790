@import '../../../../assets/scss/variables';

:host {
  width: 100%;
  display: block;
  z-index: 20;
  position: sticky;
  top: 0;
  left: 0;

  .header {
    padding: 0 16px;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid $gray-color1;
    background-color: $white-color;

    .navigation {
      width: 60%;
      flex: 1;
      padding-left: 42px;
      display: flex;
      justify-content: flex-start;
    }

    .login {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span {
        display: inline-block;
        padding-right: 20px;
        font-weight: 500;
        font-size: 14px;
        //@extend .gray-font-1;
        //@extend .font-base;
      }

    }
  }

  .sing-in {
    cursor: pointer;
  }
}

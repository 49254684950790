@import '../../../../../assets/scss/variables';

app-advances-popup {
  .popup-wrapper .title {
    margin-bottom: 10px;
  }

  .inner {
    max-width: 500px;
    width: 90vw;
    position: relative;
    padding: 0 !important;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    .button {
      max-width: 45%;
      width: 45%;
    }
  }

  .form-row {
    justify-content: space-between;
  }

  .recoupable-rights {
    display: flex;
    justify-content: flex-end;
  }

  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }

  .form {
    padding: 0 30px 30px;
  }

  .item {
    width: 50%;
    margin: 5px 0;
    max-width: 206px;
  }

  .calendar {
    .mat-form-field-flex {
      padding: 0;
    }

    .mat-form-field-infix {
      padding: 0;
    }
  }

  mat-radio-button {
    margin-right: 15px;
  }

  .mat-checkbox-frame {
    border-color: $black-color1;
  }

  .label {
    font-family: $default-font;
    text-transform: capitalize;
    font-size: $default-font-size;
    color: $gray-color2;
    line-height: 2;
    transition: all linear .1s;

    &.opened {
      transform: translateY(-19px);
    }
  }

  .error-message {
    &.align-block {
      display: flex;
    }

    .error-message-text {
      display: inline-block;
    }
  }

  .select[multiple] {
    min-height: 80px;
  }
}


@import '../../../../assets/scss/variables';

app-biography-multiple {
  display: block;
  margin: 20px 0;
  .content-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .remove-b{
    margin: 20px 0;
  }
  .tab-item{
    margin-bottom: 20px;
  }
  form{
    display: block;
    width: 100%;
  }
  .section-title {
    font-size: 18px;
  }
  .artist{
    margin: 25px 0;
  }
  .item-label{
    width: 200px;
    min-width: 200px;
    padding:0 20px;
    text-align: right;
  }
}

@import '../../../../assets/scss/variables';

app-find-user-statement-upload {
  .search__elem {
    &.search__elem_m-right {
      margin-right: 20px;
    }
    .mat-input {
      width: 180px;
    }
    &.search__field {
      display: flex;
    }
  }
  .button{
    height: 35px;
    margin-left: 20px;
  }
  .content__row {
    font-size: 13px;
    display: flex;
    &.content__row_flex-end {
      justify-content: flex-start;
    }
  }
}

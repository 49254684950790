@import "../../../../assets/scss/variables";

app-find-producer-by-name {
  width: 100%;
  position: relative;
  display: block;
  .listDrpDown {
    position: absolute;
    width: 100%;
    height: auto;
    top: 45px;
    z-index: 50;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background: #ffffff;
    max-height: 200px;
    overflow-y: scroll;
    .autocomplete-item {
      @extend .font-base-13;
      padding: 5px 10px;
      color: $gray-color5;
      cursor: pointer;
      &:hover {
        background-color: $gray-color2;
      }
    }
  }
  mat-form-field {
    width: 100%;

    &:has(input[readonly]) {
      opacity: 0.5;
    }
  }

  .search {
    width: 100%;
    display: flex;
    padding: 0;
    align-items: center;
    white-space: nowrap;
    span {
      display: inline-block;
      margin-right: 20px;
      font-weight: 600;
      @extend .gray-font-4;
      @extend .font-base-13;
    }
    .warning {
      color: $red-color1;
      font-size: 11px;
    }
    .search-field {
      width: 100%;
      position: relative;
      height: 40px;
    }
  }
  input {
    padding: 0 !important;
  }
}

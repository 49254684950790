@import '../../../../assets/scss/variables';

app-upload-file {
  display: block;
  .wrapper{
    display: flex;
  }
  .upload-button {
    height: 35px;
    padding: 0 15px;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    display: flex;
    background: $blue-color11;
    .desktop-upload {
      visibility: hidden;
      width: 0;
    }
    &.disabledUpload {
      opacity: .6;
    }
  }
}


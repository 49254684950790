@import '../../../../assets/scss/variables';

app-upload-image {
  display: block;
  width: 100%;
  max-width: 400px;

  .upload__image-wrapper {
    margin-top: 20px;
  }
  .upl__image {
    width: 100%;
  }

  .hide {
    visibility: hidden;
  }
  .upl__name {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #555;
  }
  .fa-times-circle {
    position: relative;
    cursor: pointer;
    padding-right: 15px;
  }
  .change-picture {
    position: relative;
    overflow: hidden;
    padding: 10px 0;
    &.button {
      max-width: 150px;
    }
    input {
      position: absolute;
      padding: 15px 10px;
      height: 0;
      left: 0;
      top: 5px;
      width: 120px;
      opacity: 0;
      cursor: pointer;
    }
  }
}

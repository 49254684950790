@import '../../../../assets/scss/variables';

app-navigation {
  @extend .blue-dark-color;
  z-index: 1;
  position: relative;
  top: 0;
  bottom: 0;
  transition: all 200ms ease-in;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 250px 0 0;

  .nav-header {
    display: flex;
    height: 60px;
    justify-content: flex-start;
    img{
      width: 150px;
      height: 18px;
      position: absolute;
      right: 20px;
      top: 22px;
    }
    .menu-toggle-wrapper {
      height: 100%;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-start;
      padding: 20px;
      .menu-toggle {
        display: inline-block;
        font-size: 24px;
        width: 28px;
        color: $white-color;
        font-weight: normal;
        &:hover, &:active {
          text-decoration: none;
        }
      }
    }
  }
  .fa{
    @extend .gray-color-font;

  }
  .sub-wrapper {
    height: 0;
    transition: height ease-in-out .2s;
    overflow: hidden;
  }
  .client.opened {
    height: auto;
  }
  .main-navigation {
    padding-top: 40px;
    .link {
      @extend .font-base-13;
      @extend .gray-color-font;
      padding: 14px 20px 14px 25px;
      position: relative;
      line-height: 1;
      cursor: pointer;
      display: flex;
      font-weight: 500;
      align-items: center;
      border-left: 4px solid transparent;
      justify-content: flex-start;

      .icon {
        width: 75px;
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        color: transparentize($menu-link-color, 0.4);

      }

      .text {
        white-space: nowrap;
        line-height: 16px;
        margin-left: 20px;
      }

      .notifications {
        color: $white-color;
        margin-left: 10px;

      }
      &:hover {
        background: $blue-color1;
        text-decoration: none;
        .text {

          @extend .white-color;
        }
        .fa {
          @extend .white-color;
        }
      }
      &.active {
        background: $blue-color1;
        text-decoration: none;
        border-left: 4px solid $green-color1;
        @extend .white-color;
        .fa {
          @extend .white-color;
        }

        .text {
          @extend .white-color;
        }
      }
      .icon {
        display: inline-block;
      }
    }
  }
  .sub {
    padding-left: 55px;
    height: 40px !important;
    .text {
      padding-left: 20px;
    }
  }

  .lnr-chevron-right {
    color: $gray-color1;
    font-size: 10px;
    font-weight: 600;
    transition: all linear .2s;
    &.rotate {
      transform: rotate(90deg);
    }
  }
  &.collapsed {
    overflow-y: inherit;
    flex: 70px 0 0;
    
    .link {
      width: 100%;
      .text {
        display: none;
      }
      &.active {
        width: 100% !important;
      }
    }

    &:hover {
      overflow-x: hidden;
    }

    .lnr-chevron-right {
      display: none;
    }
    > .logo {
      height: 60px;
      width: 30px;
      margin-left: 11px;
      justify-content: flex-start;

      .img {
        height: 35px;

      }

      .text {
        display: none;
      }
    }

    .main-navigation {
      .link {
        .text {
          display: none;
        }

        .icon {
          width: 53px;
        }
      }
    }
  }

  .navigation-loader {
    margin: 50px auto;
    .lnr {
      font-size: 50px;
    }
  }
}

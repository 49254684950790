@import '../../../../assets/scss/variables';

:host {
  display: block;
  margin: 20px 0;
  .row{
    display: flex;
    flex-direction: column;
  }
  .item-edit-row{
    width: 100%;
  }
  .item-select{
    margin-right: 20px;
    min-width: 288px;
    width: 40%;
  }
  .share__value{
    width: 180px;
  }
  form{
    display: flex;
    width: 100%;
  }
}

@import '../../../assets/scss/variables';

app-submission-fields {
  .list-item {
    display: flex;
    position: relative;
    justify-content: space-between;
    .list-controls {
      position: relative;
    }
    .add {
      padding-right: 30px;
      padding-top: 20px;
      cursor: pointer;
      color: $blue-color11;
    }
    .fa-times-circle {
      color: $red-color1;
    }
    .remove-control {
      cursor: pointer;
      position: absolute;
      right: -20px;
      top: 10px;
    }
    .mat-item {
      width: 380px;
    }
  }
  .item-edit {
    width: 100%;
  }
  .mat-item {
    width: 100%;
  }
  .find-user {
    .find-user__wrapper {
      width: 100%;

    }
    .add {
      white-space: nowrap;
    }
  }
  .mat-form-field-infix {
    border-top: 0.84375em solid transparent;
  }
  .wrapper-content {
    margin: 0 34px;
  }
  .item-document {
    margin-bottom: 20px;
  }
  .dialog-footer {
    padding: 20px 34px;
  }
  .upload-section {
    width: 100%;
    height: 150px;
    label {
      flex-direction: row;
    }
    .fa-music, .fa-camera {
      font-size: 20px;
      padding-right: 18px;
      color: $blue-color11;
    }
  }
  .genres {
    span {
      margin-right: 10px;
    }
  }
  .file-name {
    font-weight: 600;
    display: inline-block;
    margin: 10px 0;
    .icon-cancel {
      padding-right: 10px;
    }
    .fa-times-circle {
      color: $red-color1;
      cursor: pointer;
    }
  }
  .footer-content {
    padding: 0 !important;
    .footer-item {
      &:first-child {
        width: 10%;
        color: $gray-color7;
      }
      &:last-child {
        width: 90%;
      }
    }
  }
}

@import '../../../../../assets/scss/variables';

app-popup-collectible-auction {
  .inner {
    width: 560px;
    max-width: 90vw;
    position: relative;

  }
  .content {
    padding: 0 30px 30px 30px;
  }
  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }
  .start-auction__button {
    width: 150px;
    height: 43px;
  }

  .date {
    .auction__param {
      margin: 20px 0 0;
    }
    #item-hour {
      width: 100px;
    }
    .date-end {
      display: flex;
      justify-content: space-between;
    }
    .date-time {
      display: flex;
      align-items: flex-end;
    }
    .date-points {
      margin: 15px 10px;
    }
  }
  .text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }
  #collectible {
    .button-wrapper {
      width: 100%;
      margin-top: 20px;
      justify-content: space-between;
    }
    .button {
      font-size: 16px;
    }
  }
}

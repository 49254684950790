@import 'variables';

/* RESET STYLES*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

blockquote, q {
    quotes: none;
}

//* remember to define focus styles! */
:focus {
    outline: 0;
}
.angular-editor{
  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }
  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
  }
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
}
//* remember to highlight inserts somehow! */
ins {
    text-decoration: none;
}

del {
    text-decoration: line-through;
}

//* tables still need ?cellspacing=?0?? in the markup */
table {
    border-collapse: collapse;
    border-spacing: 0;
    td, th {
        padding: 0;
    }
}

input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

//*--- Text elements --- */
p {
    margin: 0;
}

p img.left {
    float: left;
    padding: 0;
}

p img.right {
    float: right;
}

blockquote {
    color: #666;
    font-style: italic;
}

strong {
  font-weight: 400;
}

em, dfn {
    font-style: italic;
}

sup, sub {
    line-height: 0;
}

abbr, acronym {
    border-bottom: 1px dotted #666;
}

address {
    font-style: italic;
}

del {
    color: #666;
}

a {
    text-decoration: none;
    color: $link-color;
    transition: color $transition;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: $link-color-hover;
        outline: none;
    }
    &.active, &:active {
        color: $link-color-active;
        outline: none;
    }
}

//*--- Lists --- */


dl {
    margin: 0;
}

dd {
    margin-left: 0;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

/* ==========================================================================
   Helper classes
   ========================================================================== */

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

.hidden {
    display: none !important;
    visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &.focusable:active,
    &.focusable:focus {
        clip: auto;
        height: auto;
        margin: 0;
        overflow: visible;
        position: static;
        width: auto;
    }
}

.invisible {
    visibility: hidden;
}

.visible {
    visibility: visible !important;
}

*, *:after, *:before {
    box-sizing: border-box;
}

@import "../variables";

.content-item-v{
  // border-bottom: $border-dash-grey-light;
  font-size: 13px;
  justify-content: space-between;
  align-items: center;
  display: flex !important;
}

.item-label-v {
  width: 20%;
  min-width: 200px;
  padding-right: 30px;
  color: $gray-color4;
  font-weight: 500;
  span {
    padding: 10px;
    display: inline-block;
  }
}

///// edit
.item-row {
  display: flex !important;
  align-items: center;
  margin-bottom: 10px;
}

.item-field {
  width: 100%;
  &.item-field_align {
    display: flex;
    align-items: center;
  }
  &.item-field_align-start {
    display: flex;

    align-items: flex-start;
  }
  &.item-field_column {
    flex-direction: column;
  }
  &.item-field_border-top {
    border-top: 1px dashed $gray-color4;
  }
}

.item-section {
  position: relative;
  padding-top: 20px;
  &.item-section_border-top {
    border-top: 1px dashed $gray-color4;
  }
}

.item-label {
  width: 200px;
  min-width: 200px;
  text-align: right;
  &.item-label_pad-20 {
    padding: 0 20px;
  }
}

.content-page-blocks, .page__content {
  padding: 0;
}

.content-page-block {
  &.content-page-block_border {
    border-top: 10px solid #f3f3f4;
  }
  &.content-page-block_align-c {
    text-align: center;
  }
}

.page-text {
  &.page-text_grey-color {
    color: #676a6c;
  }
}

.content-name {
  color: #686B6D;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: bold !important;
  padding: 5px 0;
  border-bottom: 1px solid #E7EAEC;
}

.item-edit-v {
  display: flex !important;
  justify-content: flex-end;
  i {
    cursor: pointer;
  }
  &.item-edit_gap {
    column-gap: 20px;
  }
  &.item-edit-v_width {
    width: 5%
  }
}

.item-value-v {
  width: 75%;
  color: $gray-color7;
  &.item-value-v_pad {
    padding-right: 20px;
  }
}

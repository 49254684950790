@import "../variables";

.button {
  vertical-align: top;
  outline: none;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  border: $default-border;
  text-align: center;
  font-weight: bold;
  color: $white-color;
  display: block;
  font-size: 13px;
  font-family: $fontRegular;
  border: none;
  &.large-size {
    // width: 210px;
    height: 40px;
    font-size: 14px;
  }
  &.default-size {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
  }
  &.wide-size {
    width: 30%;
    padding: 10px 15px;
  }
  &.button_default_height{
    height: 35px;
  }
  &.yellow {
    border: none;
    background: $yellow-color2;
    color: $white-color;

    &:hover:not(:disabled) {
      background: darken($yellow-color2, 5%)
    }
  }
  &.red {
    border: none;
    background: $red-color1;
    color: $white-color;

    &:hover:not(:disabled) {
      background: darken($red-color1, 5%)
    }
  }
  &.green {
    border: none;
    background: $green-color1;
    color: $white-color;

    &:hover:not(:disabled) {
      background: darken($green-color1, 5%)
    }
  }
  &.transparent {
    background: transparent;
    color: $blue-color1
  }

  &.blue {
    border: 2px solid $blue-color11;
    color: $blue-color11;
    background-color: transparent;
    &:hover:not(:disabled) {
      border: 2px solid darken($blue-color11, 5%);
      color: darken($blue-color11, 5%);
    }
  }

  &.blue-bgc {
    // border: 2px solid $blue-color11;
    color: $white;
    padding: 10px 15px;
    box-sizing: border-box;
    background: $blue-color11;
  }

  &.border-blue {
    border: 2px solid $blue-color11;
    color: $blue-color11;
    box-sizing: border-box;
    border-radius: 5px;
    background: $white;
  }
  &.disabled {
    background: $gray-color5;
    color: $black-color1;
    cursor: inherit;
  }

  &:hover {
    text-decoration: none;

    .lnr, .fas {
      background: inherit;
    }
  }

  .lnr {
    margin-right: 8px;
  }

  &.reset {
    min-width: 120px;
    margin-top: 23px;
  }

  &.blue-btn {
    color: $blue-color11;
    border: 2px solid $blue-color11;
    background: $white-color;

    &:hover {
      border: 2px solid $blue-color5;
      background: $white-color;
      color: $blue-color5;
    }
  }

  &.red-btn {
    background: $red-color1;
    border: 2px solid $red-color1;
    color: $white-color;

    &:hover {
      border: 2px solid $red-color3;
      background: $red-color3;
      color: $white-color;
    }
  }

  &:disabled {
    opacity: .6;
  }
}
// custom BEM
.form-input {
  &.form-input_right-m20 {
    margin-right: 20px;
  }
}
//font BEM
.font_bold{
  font-weight: bold;
}
.bold-style {
  font-weight: bold;
}

.title-global {
  &.title-warning {
    color: $red-color1;
  }
}

.buttons-wrapper {
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-phone {
  border: 1px solid $gray-color1;
  display: block;
  height: 35px;
}

.prefix-phone {
  margin-left: 7px;
}

.form-row, .row-flex {
  display: flex;
  width: 100%;
}

.item-50 {
  width: 50%;
}

.flex-wrapper {
  display: flex;
}

.fa-trash-o {
  color: $red-color1;
  font-size: 18px;
}

.fa-edit {
  color: $blue-color11;
  font-size: 18px;
}

.fa-times-circle {
  color: $red-color1;
  font-size: 18px;
}

.fa-file-alt, .fa-save {
  color: $green-color1;
  font-size: 18px;
}

.text-uppercase {
  text-transform: uppercase;
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  text-transform: capitalize;
  font-size: 12px;
  font-family: $fontRegular;
  color: #bbc4c9;
  //text-indent: 10px;

}

input::-moz-placeholder { /* Firefox 19+ */
  text-transform: capitalize;
  font-size: 12px;
  font-family: $fontRegular;
  color: #bbc4c9;
}

input:-ms-input-placeholder { /* IE 10+ */
  text-transform: capitalize;
  font-size: 12px;
  font-family: $fontRegular;
  color: #bbc4c9;
}

.form {
  padding: 0 24px;

  .error {
    //.label {
    //  color: $red-color3;
    //}
    .input-text,
    .select,
    .textarea,
    .item-phone,
    .c-btn,
    .select2-selection,
    .button-radio {
      border-color: $red-color3;
    }

    .label, .button-radio-label {
      color: $red-color3;
    }

    .input {
      border-color: $red-color3;
    }

    .error-message {
      color: $red-color3;
      font-size: 12px;
      margin-top: 5px !important;
      font-family: $fontRegular;
    }
  }
}

.error-message {
  color: $red-color3;
  font-size: 12px;
  margin: 15px 0 0 0;
  font-family: $fontRegular;
}

.textarea {
  border: $default-border;
  border-radius: 3px;
  padding: 6px 11px;
  font-size: 14px;
  font-family: $fontRegular;
  max-width: 100%;
  min-width: 100%;
  color: $default-font-color;
  resize: vertical;
  min-height: 90px;
  height: 180px;
}

.select {
  border: $default-border;
  border-radius: 3px;
  padding: 5px 24px 5px 0px;
  font-size: 14px;
  min-height: 35px;
  font-family: $fontRegular;
  max-width: 100%;
  cursor: pointer;
  min-width: 180px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: $default-font-color;

  optgroup {
    color: $black-color1;
  }

  option {
    color: $default-font-color;
  }

  &:not([multiple]):not(.readonly) {
    background: $white-color url(/assets/images/icon-select.png) no-repeat right 10px center;
  }

  &[multiple] {
    height: 91px;
    padding: 0;

    option {
      padding: 5px 10px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:checked {
        background: linear-gradient(0deg, $green-color7 0%, $green-color7 100%);
        font-weight: bold;

        &:after {
          display: inline-block;
        }
      }
    }
  }
}

.form-popup > .row > .item {
  width: 48%;
  display: flex;
  flex-direction: column;
  margin-top: 15px;

  &.full {
    width: 100%;
    position: relative;
  }

  &.half {
    width: 66%;
    position: relative;
  }

  &.error {
    border-bottom-color: $red-color1;

    .label {
      color: $red-color1;
    }

    .input, app-datepicker {
      border-color: $red-color1;
    }

    .error-message {
      color: $red-color1;
      font-size: 12px;
      margin-top: 5px !important;
    }
  }
}

.disabled-radio {
  opacity: .5;
}

.item-radio-button {
  flex-direction: row !important;
  color: #748394;
}

.input-text {
  background-color: transparent;
  color: $black-color1;
  // line-height: 32px;
  height: 34px;
  border-radius: 3px;
  border: 1px solid $gray-color1;
  font-size: $default-font-size;
  display: block;
  text-align: left;
  padding: 6px 0;
  width: auto;
  margin: 0;

  &:disabled {
    cursor: not-allowed;
    background: #edeff2;
  }

  &::placeholder {
    text-transform: capitalize;
    font-size: $default-font-size;
    color: rgba(0, 0, 0, 0.8)
  }

  &:focus {
    + .bottom {
      width: 100%;
      margin-left: -50%;

      + .label {
        transform: translateY(-19px);
      }
    }
  }
}

.item {
  .input {
    @extend .input-text;
  }

  .label {
    font-family: $default-font;
    text-transform: capitalize;
    font-size: $default-font-size;
    line-height: 2;
    transition: all linear .1s;

    &.opened {
      transform: translateY(-19px);
    }
  }

  .label.required:after {
    content: '*';
    color: $red-color1;
    margin-left: 3px;
  }
}

.mat-form-field-wrapper {
  width: 100%;
}

@import '../../../../assets/scss/variables';

app-datepicker {
  position: relative;
  cursor: pointer;
  display: block;
  z-index: 9;
  border: 1px solid $gray-color1;
  height: 34px;
  border-radius: 3px;

  &.opened {
    z-index: 11;

    .calendar-wrapper {
      display: block;
      padding-bottom: 10px;
    }
  }

  &.to-top {
    .calendar-wrapper {
      top: auto;
      bottom: 29px;
      border-radius: 5px 5px 0 0;
    }
  }

  &.to-right {
    .calendar-wrapper {
      left: auto;
      right: 0;
    }
  }

  .input-text {
    padding-right: 30px;
    width: 100%;
    height: 100%;
  }

  .lnr-calendar-full {
    position: absolute;
    top: 8px;
    right: 10px;
    color: $blue-color1;
    cursor: pointer;
  }
  .input-text-empty {
    cursor: pointer;
  }
  .calendar-wrapper {
    position: absolute;
    top: 29px;
    left: 0;
    display: none;
    // padding-top: 25px;
    background: $white-color;
    border: $default-border;
    border-radius: 0 0 5px 5px;

    .header {
      //  margin-bottom: 15px;
      padding: 20px 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        line-height: 1;
        text-transform: uppercase;
        font-size: 14px;
        background: transparent !important;
        border: none !important;
        cursor: pointer;
        min-width: auto !important;
        color: $blue-color1;
        text-decoration: underline;
      }

      .lnr {
        cursor: pointer;
        font-size: 14px;
        color: $blue-color1;
        margin-left: 0;

        &.disabled {
          opacity: .5;
          cursor: default;
        }
      }
    }

    .date-row {
      padding: 0 20px 2px !important;
      display: flex !important;
      align-items: center;
      justify-content: space-between !important;
      width: 280px !important;

      &.row-wrapped {
        flex-wrap: wrap;
        width: 280px;
      }

      > .item {
        width: 34px !important;
        height: 34px !important;
        line-height: 32px;
        font-size: 14px;
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        text-align: center;
        cursor: default;

        &:not(.disabled) {
          cursor: pointer;
        }

        &.active {
          background: $blue-color2;
        }
        &.activeLast {
          background: $blue-color2;
          border-bottom-right-radius: 20px;
          border-top-right-radius: 20px;
        }
        &.activeStart {
          background: $blue-color2;
          border-bottom-left-radius: 20px;
          border-top-left-radius: 20px;
        }
        &.last, &.disabled {
          color: $gray-color2;
        }

        &.current {
          border: $default-border;
          border-radius: 3px;
        }

        &.month {
          width: 60px !important;
          height: 60px !important;
          line-height: 60px;
          cursor: pointer;

          &:hover {
            background: $blue-color2;
          }
        }
      }
    }
  }

  .calendar-helper {
    margin: 10px 20px 30px;
    background: $gray-color4;
    border: $default-border;
    border-radius: 5px;
    padding: 15px 20px;

    .item {
      position: relative;
      padding-left: 25px;

      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 2px);
        left: 0;
        width: 8px;
        height: 0;
        border-radius: 1px;
      }
    }

    .amr {
      margin-bottom: 10px;

      &:before {
        border-top: 2px solid $red-color1;
        border-bottom: 1px solid $red-color1;
      }
    }

    .determination:before {
      border-top: 2px solid $green-color1;
      border-bottom: 1px solid $green-color1;
    }
  }

  .row.day-title {
    border-bottom: $default-border;
    padding-bottom: 0;
    margin: -10px 0 10px;
  }
}

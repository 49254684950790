:host{
  .item-label {
    width: 200px;
    min-width: 200px;
    padding-right: 30px;
    margin-top: 12px;
    text-align: right;
    font-weight: 500;
    font-size: 13px;
    color: #555;
  }
}

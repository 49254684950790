@import '../../../../assets/scss/variables';

app-add-multi-input {
  .item-label {
    width: 100%;
    text-align: left;
    margin: 20px 0;
  }
  .add {
    color: $blue-color11;
    font-weight: bold;
    cursor: pointer;
  }
  .list-controls {
    position: relative;
  }
  .remove-control {
    position: absolute;
    top: 12px;
    right: 0;
    cursor: pointer;
  }
}

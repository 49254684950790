@import '../../../assets/scss/variables';

app-image-cropper {
  .desktop-upload {
    display: none;
  }
  .crop__upload {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .button {
    padding: 10px 0;
    width: 150px;
  }

}


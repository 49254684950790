@import '../../../../assets/scss/variables';

app-social-links-song {
  .social-item {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    .wrapper-link {
      display: flex;
      width: 100%;
      padding-left: 5px;
      justify-content: space-between;
      .edit {
        color: $blue-color11;
        cursor: pointer;
      }
      .link {
        height: 44px;
        line-height: 28px;
        a {
          color: $blue-color11;
        }
      }
    }
    .social-item-icon {
      width: 10%;
      position: relative;
    }
    .social-item-url {
      width: 90%;
      color: $gray-color7;
      mat-form-field {
        width: 100%;
      }
    }
    .social {
      &.youtube, &.soundcloud, &.dropbox, &.instagram, &.tiktok, &.spotify {
        top: -24px;
        position: absolute;
      }
    }
    .remove-control {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      color: $red-color1;
    }
    .mat-form-field-flex {
      width: 90%;
    }
    .save-control {
      right: 25px;
      position: absolute;
      top: 0;
      cursor: pointer;
      color: $green-color1;
    }
  }
}

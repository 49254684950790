//Colors=====================================================
$black-color1: #000;
$black-color2: #051B22;
$gray-color1: #e7eaec;
$gray-color2: #f3f3f4;
$gray-color3: #a7b1c2;
$gray-color4: #676a6c;
$gray-color5: #555;
$gray-color6: #686B6D;
$gray-color7: #8089A0;
$gray-color8: #E5E5E5;
$gray-color9: #DFEBF4;
$gray-color10: #B7BCCB;

$white-color: #fff;
$white-color2: #dbe4ee;
$blue-color1: #293846;
$blue-color2: #2F4050;
$blue-color3: #1b467f;
$blue-color4: #084C61;
$blue-color5: #0F8F97;
$blue-color6: #14B6C0;
$blue-color7: #273C73;
$blue-color8: #00d1ff;
$blue-color9: #2c53b7;
$blue-color10: #0274B3;
$blue-color11: #5AA2E5;

$green-color1: #95C962;
$green-color2: #8bc24a;
$green-color3: #71a534;
$green-color4: #c1e19a;
$green-color5: #f7fae8;
$green-color6: #8cc052;
$green-color7: #c6e0a9;
$green-color8: #F2FFED;
$green-color9: #82C76A;
$green-color10: #27AE60;
$green-color11: #1ed760;
$green-color12: #25d366;
$red-color1: #F95738;
$red-color2: #eb3b32;
$red-color3: #EF3E42;
$red-color4: #C02424;

$red-color5: #fff3f0;
$red-color6: #FFE9E4;
$red-color7: #e0518d;
$red-color8: #f95738;
$red-color9: #9a463d;
$red-color10: #E81B22;
$yellow-color1: #faf6e3;
$yellow-color2: #FFA800;
$yellow-color3: #fffef0;
$yellow-color4: #FFC700;
$yellow-color5: #FF7A00;
$white: #fff;

$default-border-dash: 1px dashed $gray-color4;
$border-dash-grey-light: 1px dashed $gray-color1;
$border-dash-grey-light: 1px solid $gray-color1;


$fontRegular: "Roboto", "Helvetica Neue", sans-serif; ;
$default-font: $fontRegular;
$default-font-size: 14px;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-700: 700;
$font-weight-900: 900;
$menu-font-size: 15px;
$default-font-color: $black-color1;
$default-line-height-computed: 1.2;

$link-color: $blue-color1;
$link-color-hover: darken($blue-color1, 20%);
$link-color-active: darken($blue-color1, 20%);
$default-input-border-color: $gray-color1;
$transition: linear .2s;
$default-border-color: #949494;
$default-border: 1px solid $default-border-color;
$default-shadow: 0 0 3px $gray-color3;
$block-shadow: 0 1px 2px #B4BCC6;
$transition1: linear .2s;

$menu-link-color: #fff;
$menu-link-bg-active-color: $blue-color2;
$menu-link-icon-active-color: $green-color1;
$menu-link-border-color: $green-color1;
$header-height: 45px;
$footer-height: 68px;

$table-padding: 0 25px;


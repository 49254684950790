@import '../../../../assets/scss/variables';

app-find-by-name-universal {
  width: 100%;
  position: relative;

  mat-form-field {
    width: 100%;
  }
  .search-field {
    width: 100%;
    position: relative;
    height: 40px;
  }
  input {
    padding: 0 !important;
  }
}

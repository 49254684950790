@import '../../../../../assets/scss/variables';

app-popup-generate-voucher {
  .inner {
    width: 420px;
    max-width: 90vw;
    position: relative;
    padding: 30px;
  }

  .lnr-cross {
    position: absolute;
    right: 20px;
    font-size: 16px;
    font-weight: 600;
    top: 20px;
    cursor: pointer;
    color: $blue-color1;
  }

  .block-title {
    font-weight: 900;
    font-size: 21px;
    text-transform: uppercase;
    text-align: center;
    margin: 30px 0 10px;
  }

  .text {
    font-size: 15px;
    text-align: center;
    margin-bottom: 30px;
  }
  .content {
    text-align: center;
  }
  .image {
    display: block;
    margin: 0 auto;
    height: 130px;
  }

  mat-form-field {
    width: 100%;
  }

  .fa-envelope {
    color: $link-color;
    margin: 0 20px 0 12px;
  }

  .button {
    max-width: 170px;
    width: 100%;
    margin: 0 5px;
    font-size: 16px;
  }
  .button-wrapper{
    justify-content: center;
  }
}

@import "../variables";

tbody {
  tr {
    &.primary, &.primary:hover {
      td {
        background-color: $white-color;
        box-shadow: 0 1px 2px #C7CFD8;
      }
    }

    &:hover {
      td {
        background: $white-color
      }
    }
  }

  td {
    padding: 11px 10px;
    border-top: 1px solid #e7eaec;
    background-color: transparent;
    color: #8089A0;
    font-size: 13px;
    vertical-align: middle;

    &.warning {
      font-family: $font-weight-900;
      color: $red-color1;
    }

    &.no-list-data {
      padding: 15px;
      text-align: center;
      font-family: $font-weight-900;
    }
    &.td-no-padding{
      padding: 11px 0;
    }
    &.table-actions {
      max-width: 150px;
      width: 150px;
    }
  }
}

//----------CUSTOM------------
.table-vertical-align {
  display: flex;
  align-items: center;
  justify-content: center;
}
.table__actions{
  display: flex;
  justify-content: space-around;
}
.table-cell{
  .table-cell--align{
    text-align: center;
  }
}

.table-width-140 {
  width: 140px;
  max-width: 140px;
}

//----------------------------
th.checkbox {
  width: 56px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  //background-color: #f9f9f9;
}

.table {
  width: 100%;

  &.table-uniq {
    tbody {
      td {
        &:first-child, &:nth-child(2) {
          span {
            //display: none;
          }
        }
      }
    }
  }

  th, app-th-sortable {
    @extend .font-base-13;
    color: #676a6c;
    font-weight: 500;
    background: transparent;
    text-align: left;

    font-family: $font-weight-900;
    cursor: pointer;
    vertical-align: middle;
  }

  th {
    padding: 11px 10px;
  }
  .table-sub-title {
    text-align: left;
    font-size: 11px;
    font-weight: 400;
    font-family: $font-weight-900;
    cursor: pointer;
    vertical-align: top;
  }

  .success-checkmark {
    margin-left: 15px;
    border-radius: 14px;
    position: relative;

    &:before {
      content: '';
      vertical-align: top;
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      margin: -5px 0 0 -18px;
      height: 6px;
      width: 12px;
      border: solid #888;
      border-width: 0 0 2px 2px;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }

  .actions {
    white-space: nowrap;
    width: 1%;
    text-align: center;
  }

  .centered {
    text-align: center;

    .table-title {
      justify-content: center;
    }
  }

  .checkmark {
    top: -3px;
  }
}

.table-content {
  width: 100%;
  thead {
    border-bottom: 1px solid $gray-color1;
  }
  .actions {
    display: flex;
    width: 100%;
    justify-content: center;
    .fa-pencil-alt {
      font-size: 14px;
      cursor: pointer;
      color: $blue-color11;
    }
    .fa-times {
      font-size: 14px;
      cursor: pointer;
      color: $gray-color7;
    }
  }
  tr:nth-of-type(odd) {
    background: transparent;
  }
  td {
    color: $gray-color7;
  }
  th {
    font-weight: bold;
    color: $gray-color6;
    padding: 8px 10px;
  }
  .text-break {
    word-break: break-all;
  }
  .status {
    font-size: 14px;
    display: flex;
    // padding: 2px 8px 2px 2px;
    max-width: 140px;
    width: 140px;
    height: 26px;
    align-items: center;
    .fa-clock, .fa-pause, .fa-user {
      padding: 0 8px;
    }
    &.yellow-bg {
      color: $white;
    }
    &.red-bg {
      color: $white;
    }
  }
}

@import '../../../../assets/scss/variables';

app-release-revenue {
  .checked {
    color: $yellow-color4;
  }
  .upload-button {
    width: 120px;
    height: 35px;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    display: flex;
    background: $blue-color11;
    .desktop-upload {
      visibility: hidden;
      width: 0;
    }
  }
  .col-button {
    .button {
      margin: 0 auto !important;
    }
  }
  .item-album {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table {
    .button {
      margin: 0;
    }
  }
  .url {
    font-size: 12px;
    line-height: 1.2;
  }
  .item {
    max-width: 120px;
    margin: 0 auto;
    min-width: 120px;
    .content-item {
      width: 100%;
    }
    &.item-song--height {
      height: 53px;
    }
  }

  .actions {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }

  .actions {
    display: flex;
    .actions-icon {
      cursor: pointer;
      padding: 0 5px;
    }
  }
  .table-title {
    font-size: 18px;
    padding-right: 30px;
  }
  .add-revenue {
    align-items: center;
  }
}
